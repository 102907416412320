import {
    CustomCol,
    CustomRow,
    DetailWrapper,
    SimpleTable
} from '../../../components';
import { URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { REDUX_NODE_NAME } from '../../../redux';

export function DetailTachesPerformanceAgentForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({ nodeName: 'tachesPerformanceAgent' });
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_LIST_TACHE_PERFORMANCE_AGENT_BY_ID}
            nodeName={REDUX_NODE_NAME.TACHES_PERFORMANCE_AGENT}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Detail"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
        >
            <CustomRow isShouldBeRendered={entity.plages.length}>
                <div className="mt-3">
                    <h5>Plages</h5>
                    <hr />
                </div>
                <CustomCol className="col-12">
                    <SimpleTable
                        isActionActive={false}
                        isShowDropButton={false}
                        data={entity.plages}
                        tableClass="table table-hover shadow table-bordered table-responsive-sm"
                        filter={false}
                        bottomPagination={false}
                        isLinkToDetail={false}
                        mapper={[
                            {
                                field: 'debut',
                                displayName: 'Debut'
                            },
                            {
                                field: 'fin',
                                displayName: 'Fin'
                            },
                            {
                                field: 'niveau',
                                displayName: 'Niveau'
                            }
                        ]}
                    />
                </CustomCol>
            </CustomRow>
        </DetailWrapper>
    );
    return renderContent();
}
