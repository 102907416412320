export const headerColumnsLines = {
    firstRow: [
        {
            name: `#`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Super Dealer`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Devise`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Solde Initial`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Solde Actuel`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Solde Physique`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Transactions Effectuées`,
            rowSpan: 2,
            colSpan: 3
        },
        {
            name: `Différence`,
            rowSpan: 2,
            colSpan: 2
        },
        {
            name: `Non Déclaré à l'ouverture`,
            rowSpan: null,
            colSpan: 4
        },
        {
            name: `Dépôt Déclaré`,
            rowSpan: 2,
            colSpan: 3
        },
        {
            name: `Retrait Déclaré`,
            rowSpan: 2,
            colSpan: 3
        },
        {
            name: `Revenu`,
            rowSpan: 3,
            colSpan: null
        },
        {
            name: `Référence Tuée`,
            rowSpan: 2,
            colSpan: 2
        },
        {
            name: `Transfert Interne`,
            rowSpan: 2,
            colSpan: 2
        },
        {
            name: `Transfert Externe`,
            rowSpan: 2,
            colSpan: 2
        },
        {
            name: `Facturation`,
            rowSpan: 3,
            colSpan: null
        },
        ///
        {
            name: `Régularisation`,
            rowSpan: 2,
            colSpan: 2
        },
        ///
        {
            name: `Non Déclaré à la fermeture`,
            rowSpan: null,
            colSpan: 4
        }
    ],
    secondRow: [
        { name: `Dépôt`, rowSpan: null, colSpan: 2 },
        { name: `Retrait`, rowSpan: null, colSpan: 2 },
        { name: `Dépôt`, rowSpan: null, colSpan: 2 },
        { name: `Retrait`, rowSpan: null, colSpan: 2 }
    ],
    thirdRow: [
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Frais`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        // {
        //     name: `Revenu`,
        //     rowSpan: null,
        //     colSpan: null
        // },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Banque`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Banque`,
            rowSpan: null,
            colSpan: null
        },
        // {
        //     name: `Revenu`,
        //     rowSpan: null,
        //     colSpan: null
        // },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        // {
        //     name: `Retrait`,
        //     rowSpan: null,
        //     colSpan: null
        // },
        // {
        //     name: `Dépôt`,
        //     rowSpan: null,
        //     colSpan: null
        // },
        {
            name: `Dépôt`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Retrait`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        },
        {
            name: `Client`,
            rowSpan: null,
            colSpan: null
        },
        // {
        //     name: `Revenu`,
        //     rowSpan: null,
        //     colSpan: null
        // },
        {
            name: `Autres`,
            rowSpan: null,
            colSpan: null
        }
    ]
};

export const pathMapper = [
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.SoldeInitial.montant'
    },
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.SoldeActuel.montant'
    },
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.SoldeCloture.montant'
    },
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.Depot.montant'
    },
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.Retrait.montant'
    },
    {
        path: 'categories.TRANSACTION_EFFECTUEE.operation.Frais.montant'
    },
    {
        path: 'categories.DIFFERENCE.operation.Depot.montant'
    },
    {
        path: 'categories.DIFFERENCE.operation.Retrait.montant'
    },
    {
        path: 'categories.DEPOT_NON_DECLARER_OUVERTURE.operation.Client.montant'
    },
    {
        path: 'categories.DEPOT_NON_DECLARER_OUVERTURE.operation.Autre.montant'
    },
    {
        path: 'categories.RETRAIT_NON_DECLARER_OUVERTURE.operation.Client.montant'
    },
    // {
    //     path: 'categories.RETRAIT_NON_DECLARER_OUVERTURE.operation.Revenu.montant'
    // },
    {
        path: 'categories.RETRAIT_NON_DECLARER_OUVERTURE.operation.Autre.montant'
    },
    {
        path: 'categories.DEPOT_DECLARE.operation.Client.montant'
    },
    {
        path: 'categories.DEPOT_DECLARE.operation.Bancaire.montant'
    },
    {
        path: 'categories.DEPOT_DECLARE.operation.Autre.montant'
    },
    {
        path: 'categories.RETRAIT_DECLARE.operation.Client.montant'
    },
    {
        path: 'categories.RETRAIT_DECLARE.operation.Bancaire.montant'
    },
    {
        path: 'categories.RETRAIT_DECLARE.operation.Autre.montant'
    },
    {
        path: 'categories.RETRAIT_DECLARE.operation.Revenu.montant'
    },
    {
        path: 'categories.REFERENCE_TUEE.operation.Depot.montant'
    },
    {
        path: 'categories.REFERENCE_TUEE.operation.Retrait.montant'
    },
    {
        path: 'categories.TRANSFERT.operation.Credit.montant'
    },
    {
        path: 'categories.TRANSFERT.operation.Debit.montant'
    },
    {
        path: 'categories.TRANSFERT_EXTERNE.operation.Credit.montant'
    },
    {
        path: 'categories.TRANSFERT_EXTERNE.operation.Debit.montant'
    },
    // {
    //     path: 'categories.FRAIS.operation.Credit.montant'
    // },
    {
        path: 'categories.APPRO_STOCK.operation.Depot.montant'
    },
    {
        path: 'categories.REGULARISATION.operation.Depot.montant'
    },
    {
        path: 'categories.REGULARISATION.operation.Retrait.montant'
    },
    {
        path: 'categories.DEPOT_NON_DECLARER_FERMETURE.operation.Client.montant'
    },
    {
        path: 'categories.DEPOT_NON_DECLARER_FERMETURE.operation.Autre.montant'
    },
    {
        path: 'categories.RETRAIT_NON_DECLARER_FERMETURE.operation.Client.montant'
    },
    // {
    //     path: 'categories.RETRAIT_NON_DECLARER_FERMETURE.operation.Revenu.montant'
    // },
    {
        path: 'categories.RETRAIT_NON_DECLARER_FERMETURE.operation.Autre.montant'
    }
];
