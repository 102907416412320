import { URL_CONST } from '../../../../util';
import { ListGenericForm } from '../../../../components';

export function RapportComptabilisationSurplusKinDBForm() {
    const listFormater = (donnees) => {
        const result = (donnees || []).map((item) => {
            const userName = `${item.agent.prenom} ${item.agent.nom}`;
            return {
                ...item,
                designationAgent: userName
            };
        });

        return result;
    };
    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ListGenericForm
                isDynamicNode
                dynamicNodeName="comptabilisationSurplus"
                isLinkToDetail={false}
                listFormater={listFormater}
                functionName={
                    URL_CONST.GET_LIST_SURPLUS_COMPTABILISATION_ORGANISATION
                }
                identifier="id"
                mapper={[
                    {
                        displayName: 'Organisation',
                        field: 'typeOrganisation'
                    },
                    {
                        displayName: 'Agent',
                        field: 'designationAgent'
                    },
                    {
                        displayName: 'Montant',
                        field: 'solde',
                        isNumber: true
                    },
                    {
                        displayName: 'Montant Exact',
                        field: 'montantExact',
                        isNumber: true
                    },
                    {
                        displayName: 'Devise',
                        field: 'devise'
                    },
                    {
                        displayName: 'Dernière Modification',
                        field: 'dateDerniereModification'
                    }
                ]}
            />
        </>
    );
}
