import { CODE_SERVICE, TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { ShowNewComponent, TabsRendering } from '../../../components';
import { ConfigurationPath } from '../../../routes/registration';
import { ListUploadFichierReleveEmoneyForm } from './list-upload-fichier-releve-emoney';
import { ListHistoriqueUploadFichierReleveEmoneyForm } from './historique';
import { ClientCommandeEmoney } from '../../administration/service-client/reinitialisation/commande-emoney';

export function UploadFichierReleveEmoneyViewLayout() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const { pathname } = useLocation();
    const [key, setKey] = useState('operations');

    const getTabData = useCallback(
        () => [
            {
                eventKey: 'operations',
                title: 'Opérations',
                content: <ListUploadFichierReleveEmoneyForm />,
                isCondition: true
            },
            {
                eventKey: 'historique',
                title: 'Historique',
                content: <ListHistoriqueUploadFichierReleveEmoneyForm />,
                isCondition: true
            },
            {
                eventKey: 'search',
                title: 'Recherche Retrait',
                content: <ClientCommandeEmoney />,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            }
        ],
        [typeEntite]
    );

    const tabData = getTabData();

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Importation Rélevé Emoney</h1>
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        {pathname ===
                            `/${ConfigurationPath.UploadFichierReleveEmoneyViewLayoutPath.path}` && (
                            <ShowNewComponent
                                services={[
                                    { code: 'test' },
                                    CODE_SERVICE.SERVICE_EMONEY
                                        .CREATION_RELEVE_EMONEY
                                ]}
                                destination={
                                    ConfigurationPath
                                        .UploadFichierReleveEmoneyCreatePath
                                        .path
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            <TabsRendering data={tabData} onSelect={setKey} selectedKey={key} />
        </div>
    );
}
