/* eslint-disable react/no-array-index-key */
import { IoMdAdd } from 'react-icons/io';
import { AiFillMinusCircle } from 'react-icons/ai';
import { Fragment, useState } from 'react';
import {
    submitPostAction,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    formInitialState,
    FORM_CONST,
    fromStringToTimestamp,
    CONST_PERFORMANCE
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomButtonLoader,
    CustomCol,
    CustomRow,
    FormWrapper
} from '../../../components';
import { formatCodeDesignation, URL_CONST } from '../../../util';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {};

export function CreationTachesPerformanceAgentForm() {
    const reduxDispatcher = useDispatch();
    // Begin Region
    const [formFields, setFormFields] = useState([
        {
            code: '',
            designation: '',
            typeEntiteSuperieur: '',
            evenement: { superieur: '', inferieur: '' },
            typeDelai: '',
            indice: 0,
            quota: 1,
            typeIndicePerformance: '',
            plages: [{ niveau: 1, debut: 1, fin: 2 }],
            typeTache: '',
            ordreCorrespondance: ''
        }
    ]);
    // End Region
    /**
     * Get data on redux store
     */
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    /**
     * Common form processes
     */
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, form } = formState;
    const handleSubmit = (e) => {
        e.preventDefault();
        const list = formFields.map((item) => ({
            ...item,
            code: formatCodeDesignation({
                partToAdd: `OKAPI_`,
                designation: item.designation.trim()
            })
        }));
        const payload = {
            list
        };
        // console.log('payload',payload)
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_LIST_TACHE_PERFORMANCE_AGENT,
                payload,
                fields,
                reduxNodeName: REDUX_NODE_NAME.TACHES_PERFORMANCE_AGENT,
                reduxDispatcher
            })
        );
    };

    /**
     * Start Region
     */

    const castTypeOfData = ({ type, data }) => {
        if (type === 'date') return fromStringToTimestamp(data);
        if (type === 'number') return Number(data);
        return data;
    };

    const getNames = ({ name }) => {
        if (name.includes('|')) return name.split('|');
        if (name.includes('.')) return name.split('.');
        return name;
    };
    // For input
    const handleChange = ({
        index,
        event,
        indexAtSecondLevel,
        indexAtThirdLevel,
        id
    }) => {
        const values = [...formFields];
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            const names = getNames({ name: event.target.name });
            if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index][f][s] = castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
            } else {
                values[index][event.target.name] = castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
            }
        }
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            values[index].plages[indexAtSecondLevel][event.target.name] =
                castTypeOfData({
                    type: event.target.type,
                    data: event.target.value
                });
        }
        setFormFields(values);
    };

    // For Dropdown
    const handleSelectChange = ({
        index,
        event,
        indexAtSecondLevel,
        indexAtThirdLevel,
        id
    }) => {
        const values = [...formFields];
        const names = getNames({ name: event.target.name });
        /**
         * @desc: A ce stade, nous traitons toutes les valeurs
         * au premier niveau de l'arborescence (Voir le state)
         */
        if (
            typeof index === 'number' &&
            event &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number'
        ) {
            /**
             * @desc: serie de tests, pour traiter les select input avec des name de type
             * (property),
             * (property|property),
             * (object.property),
             * (object.property1|object.property2)
             */
            if (names.length > 1 && event.target.name.includes('|')) {
                const [f, s] = names;
                if (f.includes('.')) {
                    const [newFf, newFS] = getNames({ name: f });
                    values[index][newFf][newFS] = event.target.value;
                }
                if (s.includes('.')) {
                    const [newSf, newSs] = getNames({ name: s });
                    values[index][newSf][newSs] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
                if (!f.includes('.') && !s.includes('.')) {
                    values[index][f] = event.target.value;
                    values[index][s] =
                        typeof event.target.value === 'string'
                            ? event.target.selectedOptions['0'].innerText
                            : event.target.value + 9999;
                }
            } else if (names.length > 1 && event.target.name.includes('.')) {
                const [f, s] = names;
                values[index][f][s] = event.target.value;
            } else {
                values[index][event.target.name] = event.target.value;
            }
        }

        setFormFields(values);
    };

    // For Adding Element
    const handleAddHighLevel = (type, ancestorId) => {
        if (type === 'Tache') {
            // console.log('Adding Index');
            setFormFields([
                ...formFields,
                {
                    code: '',
                    designation: '',
                    typeEntiteSuperieur: '',
                    evenement: { superieur: '', inferieur: '' },
                    typeDelai: '',
                    indice: 0,
                    quota: 2,
                    typeIndicePerformance: '',
                    plages: [{ niveau: 1, debut: 1, fin: 2 }],
                    typeTache: ''
                }
            ]);
        }
        if (type === 'Plage' && typeof ancestorId === 'number') {
            const values = [...formFields];
            values[ancestorId].plages.push({ niveau: 0, debut: 0, fin: 0 });
            setFormFields(values);
        }
    };

    // For Removing Element
    const handleRemove = (index, indexAtSecondLevel, indexAtThirdLevel, id) => {
        const values = [...formFields];
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel !== 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values.length > 1
        ) {
            values.splice(index, 1);
        }
        if (
            typeof index === 'number' &&
            typeof indexAtSecondLevel === 'number' &&
            typeof indexAtThirdLevel !== 'number' &&
            typeof id !== 'number' &&
            values[index].details.length > 1
        ) {
            values[index].plages.splice(indexAtSecondLevel, 1);
        }
        setFormFields(values);
    };

    /**
     * End Region
     */

    const renderContent = () => (
        <FormWrapper formState={formState} formDispatcher={formDispatcher}>
            {formFields.map((field, index) => (
                <Fragment key={index}>
                    <div className="row d-flex align-items-center">
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Désignation
                            </span>
                            <input
                                type="text"
                                name="designation"
                                placeholder="Désignation Tâche"
                                className="form-control form-control-sm col-2"
                                value={field.designation}
                                onChange={(event) =>
                                    handleChange({ index, event })
                                }
                            />
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Type Entité Supérieure
                            </span>
                            <select
                                onChange={(event) =>
                                    handleSelectChange({ index, event })
                                }
                                className="form-select form-select-sm col-4"
                                aria-label="Default select example"
                                name="typeEntiteSuperieur"
                                value={field.typeEntiteSuperieur}
                            >
                                <option hidden>Selectionnez entité...</option>
                                {CONST_PERFORMANCE.REDUCTION_LISTE.sort(
                                    (a, b) =>
                                        a?.designation?.localeCompare(
                                            b?.designation
                                        )
                                ).map((element) => (
                                    <option
                                        value={element.code}
                                        key={element.code}
                                    >
                                        {element.designation}
                                    </option>
                                ))}
                            </select>
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Type Tâches
                            </span>
                            <select
                                onChange={(event) =>
                                    handleSelectChange({ index, event })
                                }
                                className="form-select form-select-sm col-3"
                                aria-label="Default select example"
                                name="typeTache"
                                value={field?.typeTache}
                            >
                                <option hidden>
                                    Selectionnez Type Tâches...
                                </option>
                                {CONST_PERFORMANCE.TYPE_TACHE_LISTE.sort(
                                    (a, b) =>
                                        a?.designation?.localeCompare(
                                            b?.designation
                                        )
                                ).map((element) => (
                                    <option
                                        value={element.code}
                                        key={element.code}
                                    >
                                        {element.designation}
                                    </option>
                                ))}
                            </select>
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Evenement Inférieur
                            </span>
                            <input
                                type="text"
                                name="evenement.inferieur"
                                placeholder="Evenement Inférieur"
                                className="form-control form-control-sm col-2"
                                value={field.evenement.inferieur}
                                onChange={(event) =>
                                    handleChange({ index, event })
                                }
                            />
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Evenement Supérieur
                            </span>
                            <input
                                type="text"
                                name="evenement.superieur"
                                placeholder="Evenement Supérieur"
                                className="form-control form-control-sm col-2"
                                value={field.evenement.superieur}
                                onChange={(event) =>
                                    handleChange({ index, event })
                                }
                            />
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Type Delais
                            </span>
                            <select
                                onChange={(event) =>
                                    handleSelectChange({ index, event })
                                }
                                className="form-select form-select-sm col-3"
                                aria-label="Default select example"
                                name="typeDelai"
                                value={field?.typeDelai}
                            >
                                <option hidden>Selectionnez Delais...</option>
                                {CONST_PERFORMANCE.TYPE_DELAIS.sort((a, b) =>
                                    a?.designation?.localeCompare(
                                        b?.designation
                                    )
                                ).map((element) => (
                                    <option
                                        value={element.code}
                                        key={element.code}
                                    >
                                        {element.designation}
                                    </option>
                                ))}
                            </select>
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col-3">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Quota
                            </span>
                            <input
                                type="number"
                                name="quota"
                                placeholder="Quotas..."
                                className="form-control form-control-sm col-2"
                                value={field.quota}
                                onChange={(event) =>
                                    handleChange({ index, event })
                                }
                            />
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Indice
                            </span>
                            <input
                                type="number"
                                name="indice"
                                placeholder="Indice..."
                                className="form-control form-control-sm col-2"
                                value={field.indice}
                                onChange={(event) =>
                                    handleChange({ index, event })
                                }
                            />
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Ordre
                            </span>
                            <select
                                onChange={(event) =>
                                    handleSelectChange({ index, event })
                                }
                                className="form-select form-select-sm col-3"
                                aria-label="Default select example"
                                name="ordreCorrespondance"
                                value={field?.ordreCorrespondance}
                            >
                                <option hidden>Selectionnez Ordre...</option>
                                {CONST_PERFORMANCE.TYPE_ORDRE.sort((a, b) =>
                                    a?.designation?.localeCompare(
                                        b?.designation
                                    )
                                ).map((element) => (
                                    <option
                                        value={element.code}
                                        key={element.code}
                                    >
                                        {element.designation}
                                    </option>
                                ))}
                            </select>
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <span
                                style={{
                                    fontSize: '13px',
                                    paddingBottom: '8px',
                                    lineHeight: '20px',
                                    minHeight: '20px',
                                    display: 'block',
                                    color: 'rgba(0,0,0,0.5)'
                                }}
                            >
                                Indice Performance
                            </span>
                            <select
                                onChange={(event) =>
                                    handleSelectChange({ index, event })
                                }
                                className="form-select form-select-sm col-3"
                                aria-label="Default select example"
                                name="typeIndicePerformance"
                                value={field?.typeIndicePerformance}
                            >
                                <option hidden>Selectionnez Delais...</option>
                                {CONST_PERFORMANCE.TYPE_INDICES_PERFORMANCE.sort(
                                    (a, b) =>
                                        a?.designation?.localeCompare(
                                            b?.designation
                                        )
                                ).map((element) => (
                                    <option
                                        value={element.code}
                                        key={element.code}
                                    >
                                        {element.designation}
                                    </option>
                                ))}
                            </select>
                            <div style={{ height: '16px' }}>
                                <div
                                    style={{
                                        height: 0
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {formFields[index].plages.map((detail, _index) => (
                        <div key={_index} className="border-start">
                            <div className="d-flex flex-row align-items-center">
                                <div
                                    style={{
                                        height: '46px',
                                        width: '16px'
                                    }}
                                >
                                    <p />
                                </div>
                                <div className="col-3 me-3">
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        Niveau
                                    </span>
                                    <input
                                        type="number"
                                        name="niveau"
                                        placeholder="Niveau..."
                                        className="form-control form-control-sm col-2"
                                        value={detail.niveau}
                                        onChange={(event) =>
                                            handleChange({
                                                index,
                                                event,
                                                indexAtSecondLevel: _index
                                            })
                                        }
                                    />
                                    <div style={{ height: '16px' }}>
                                        <div
                                            style={{
                                                height: 0
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3 me-3">
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        Debut
                                    </span>
                                    <input
                                        type="number"
                                        name="debut"
                                        placeholder="Debut..."
                                        className="form-control form-control-sm col-2"
                                        value={detail.debut}
                                        onChange={(event) =>
                                            handleChange({
                                                index,
                                                event,
                                                indexAtSecondLevel: _index
                                            })
                                        }
                                    />
                                    <div style={{ height: '16px' }}>
                                        <div
                                            style={{
                                                height: 0
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3 me-3">
                                    <span
                                        style={{
                                            fontSize: '13px',
                                            paddingBottom: '8px',
                                            lineHeight: '20px',
                                            minHeight: '20px',
                                            display: 'block',
                                            color: 'rgba(0,0,0,0.5)'
                                        }}
                                    >
                                        Fin
                                    </span>
                                    <input
                                        type="number"
                                        name="fin"
                                        placeholder="Fin"
                                        className="form-control form-control-sm col-2"
                                        value={detail.fin}
                                        onChange={(event) =>
                                            handleChange({
                                                index,
                                                event,
                                                indexAtSecondLevel: _index
                                            })
                                        }
                                    />
                                    <div style={{ height: '16px' }}>
                                        <div
                                            style={{
                                                height: 0
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto pt-2 me-3">
                                    <span
                                        role="button"
                                        title="Supprimer plages"
                                        className="text-secondary"
                                        tabIndex={0}
                                        onClick={() =>
                                            handleRemove(index, _index)
                                        }
                                        onKeyDown={() =>
                                            handleRemove(index, _index)
                                        }
                                    >
                                        <AiFillMinusCircle className="fs-4" />
                                    </span>
                                </div>
                                <div className="col-auto pt-2">
                                    <span
                                        role="button"
                                        title="Ajouter Plage"
                                        className="text-primary"
                                        tabIndex={0}
                                        onClick={() =>
                                            handleAddHighLevel(
                                                'Plage',
                                                index,
                                                _index
                                            )
                                        }
                                        onKeyDown={() =>
                                            handleAddHighLevel(
                                                'Plage',
                                                index,
                                                _index
                                            )
                                        }
                                    >
                                        <IoMdAdd className="fs-4" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Fragment>
            ))}
            {/* <div>
                <StateShowerComponent state={formFields} />
            </div> */}
            <CustomRow className="row mt-3">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Créer"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
