import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import { CustomInput, CustomRow } from '../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    numeroClient: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false
};

export function RapportDepotEmoneySearchForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, numeroClient } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            dateDebut,
            dateFin,
            estRetrait: false,
            numeroClient
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_RETRAIT_CLIENT,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    return (
        <>
            <MainReportComponentLarge
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={[
                    {
                        displayName: 'Marchand',
                        field: 'marchand.designation'
                    },
                    {
                        field: 'client.numero',
                        displayName: 'Numéro'
                    },
                    {
                        field: 'montant',
                        displayName: 'Montant'
                    },
                    {
                        field: 'devise',
                        displayName: 'Dévise'
                    },
                    {
                        field: 'etat',
                        displayName: 'Etat'
                    },
                    {
                        field: 'numeroReference',
                        displayName: 'Référence'
                    },
                    {
                        field: 'motif',
                        displayName: 'Motif'
                    },
                    {
                        field: 'dateCreation',
                        displayName: 'Création'
                    },
                    {
                        field: 'dateAttenteValidation',
                        displayName: 'Date Attente Validation',
                        isDate: true
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière modification'
                    }
                ]}
                dataList={elements.mouvements?.value || []}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
                // isCommandeLitigieuseEmoney
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        label="Numéro*"
                        labelClassName="col-12"
                        divClassName="col-12"
                        placeholder="Saisissez le numéro"
                        formDispatcher={formDispatcher}
                        name="numeroClient"
                        id="dnumeroClientDepotEmoneyRapportId"
                        formState={formState}
                        // uiValidator={uiValidator}
                    />
                </CustomRow>
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
