export const FIREBASE_USER = 'FIREBASE_USER';
export const NAPP_USER = 'NAPP_USER';
export const ORAGANISATION = 'ORAGANISATION';
export const THEME = 'THEME';
export const ENTITE_ADMINISTRATIVE = 'entitesAdministrative';
export const TAUX_VALIDATION = 'TAUX_VALIDATION';
export const CLIENT_ORGANISATION = 'CLIENT_ORGANISATION';
export const DEVISE_REFERENCE = 'DEVISE_REFERENCE';
export const COMPTE_IMPUTATION = 'COMPTE_IMPUTATION';
export const DIRECTION = 'DIRECTION';
export const SECTEUR = 'SECTEUR';
export const COMPTE_BANCAIRE_ORGANISATION = 'COMPTE_BANCAIRE_ORGANISATION';
export const COMPTE_BANCAIRE = 'COMPTE_BANCAIRE';
export const COMPTE_DISTRIBUTEUR = 'COMPTE_DISTRIBUTEUR';
export const COMPTE_MOBILE_MONEY = 'COMPTE_MOBILE_MONEY';
export const TARIF_RESSOURCE = 'TARIF_RESSOURCE';
export const TARIF_CHARGE = 'TARIF_CHARGE';
export const CHARGE_VARIABLE = 'CHARGE_VARIABLE';
export const COLONNE_AGENCE = 'COLONNE_AGENCE';
export const BENEFICIAIRE = 'BENEFICIAIRE';
export const ELABORATION_BUDGET = 'ELABORATION_BUDGET';
export const COMPTE_DECLARATION = 'COMPTE_DECLARATION';
export const SECTEUR_RESSOURCE_KINDB = 'SECTEUR_RESSOURCE_KINDB';
export const DECLARATION_RESSOURCE = 'DECLARATION_RESSOURCE';
export const REEVALUATION = 'REEVALUATION';
export const DETAIL_APPRO_STOCK_PRODUIT_MATERIEL =
    'DETAIL_APPRO_STOCK_PRODUIT_MATERIEL';
export const INGREDIENTS = 'INGREDIENTS';
export const AGENCES_MARCHAND = 'AGENCES_MARCHAND';
export const PRODUITS_AGENCE_MARCHAND = 'PRODUITS_AGENCE_MARCHAND';
export const PRODUIT_MARCHAND = 'PRODUIT_MARCHAND'; // For one product
export const RETRACAGE_RESSOURCE_CHARGE = 'RETRACAGE_RESSOURCE_CHARGE';
export const COLLECTION = 'COLLECTION';
export const USER_SERVICE = 'USRSERVICES';
export const DEROGATION = 'DEROGATION'; // Pour le cas de passage de point de vente vers dealer
export const PAIEMENT_COMPTE_EXECUTION = 'PAIEMENT_COMPTE_EXECUTION';
export const PAIEMENT_CAISSE_SECTORIELLE = 'PAIEMENT_CAISSE_SECTORIELLE'; // Pour le cas de paiement des caisses sectorielles
export const PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE =
    'PAIEMENT_CAISSE_SECTORIELLE_BENEFICIAIRE'; // Pour le cas de paiement des caisses sectorielles pour les différents bénéficiaires
export const FONCTION = 'FONCTION_DISPONIBLE';
export const CHARGE_A_REEVALUER = 'CHARGE_A_REEVALUER'; // Pour les charges Fixes
export const CHARGE_VARIABLES_A_REEVALUER = 'CHARGE_VARIABLES_A_REEVALUER'; // pour les charges Varaibles
export const CREDITER_CAUTION = 'CREDITER_CAUTION';
export const REMBOURSEMENT_CAUTION_BENEFICIAIRE =
    'REMBOURSEMENT_CAUTION_BENEFICIAIRE'; // Pour les remboursements des cautions
export const CAISSE_ACTIVE_USER = 'CAISSE_ACTIVE_USER';
export const USER_AGENT_VENDEUR = 'USER_AGENT_VENDEUR';
export const LOGO_TEMPON_PRODUIT = 'LOGO_TEMPON_PRODUIT';
export const COMMANDES_FLASH_SENDER_ENCOURS = 'COMMANDES_FLASH_SENDER_ENCOURS';
export const COMMANDES_FLASH_SENDER_ATTENTE = 'COMMANDES_FLASH_SENDER_ATTENTE';
export const COMMANDES_FLASH_SENDER_ANNULER = 'COMMANDES_FLASH_SENDER_ANNULER';
export const COMMANDES_FLASH_SENDER_LIVRER = 'COMMANDES_FLASH_SENDER_LIVRER';
export const IMPUTATION_STOCK_ORGANISATION = 'IMPUTATION_STOCK_ORGANISATION';
export const SERVICES_BY_ORGANISATION = 'SERVICES_BY_ORGANISATION';
export const IMPUTATION_STOCK_CAISSE = 'IMPUTATION_STOCK_CAISSE';
export const IMPUTATION_CAISSE_ORGANISATION = 'IMPUTATION_CAISSE_ORGANISATION';
export const AGENT_SENDERS = 'AGNET_SENDERS';
export const ZONE_AGENT_SENDERS = 'ZONE_AGNET_SENDERS';
export const DYNAMIC_NODE = 'DYNAMIC_NODE';
export const DYNAMIC_ETAT_NODE = 'DYNAMIC_ETAT_NODE';
export const SYS_SERVICE_BY_USERS = 'SysServiceByUsers';
export const INSTITUTION_FINANCIERE_TO_APPROVE =
    'INSTITUTION_FINANCIERE_TO_APPROVE';
export const MONNAIE_LOCALE_TO_APPROVE = 'MONNAIE_LOCALE_TO_APPROVE';
export const UPLOAD_RELEVE_BANCAIRE = 'UPLOAD_RELEVE_BANCAIRE';
export const SYS_SERVICE_BY_PROFIL_DEDIE = 'SYS_SERVICE_BY_PROFIL_DEDIE';
export const CHARGE_FIXE = 'CHARGE_FIXE';
export const LIMIT_CREDIT = 'LIMIT_CREDIT';
export const CAISSE_ACTIVE_ORGANISATION = 'CAISSE_ACTIVE_ORGANISATION';
export const CAISSE_SECTORIELLE = 'CAISSE_SECTORIELLE';
export const MONNAIE_MARCHAND = 'MONNAIE_MARCHAND';
export const COUPURE_DEVISE = 'COUPURE_DEVISE';
export const ELABORATION_AMENDEMENT_DETAIL = 'ELABORATION_AMENDEMENT_DETAIL';
export const COMPTE_LIMITE_CREDIT = 'COMPTE_LIMITE_CREDIT';
export const COMPTE_LIMITE_CREDIT_ORGANISATION =
    'COMPTE_LIMITE_CREDIT_ORGANISATION';
export const BALANCE_ORGANISATION = 'BALANCE_ORGANISATION';
export const STOCK_ORGANISATION = 'STOCK_ORGANISATION';
export const STOCK_SUPER_DEALER_INTEGRER = 'STOCK_SUPER_DEALER_INTEGRER';
export const STOCK_PHYSIQUE = 'STOCK_PHYSIQUE';
export const ALL_BALANCE_ORGANISATION = 'ALL_BALANCE_ORGANISATION';
export const ALL_BALANCE_ORGANISATION_TRANSFERT_FONDS =
    'ALL_BALANCE_ORGANISATION_TRANSFERT_FONDS';
export const ALL_COMPTE_CAUTION_ORGANISATION =
    'ALL_COMPTE_CAUTION_ORGANISATION';
export const ORGANISATION_REPORT = 'ORGANISATION_REPORT';
export const ALL_BALANCE_CASH_COLLECTEUR = 'ALL_BALANCE_CASH_COLLECTEUR';
export const SUPER_DEALER_KIN_DB = 'SUPER_DEALER_KIN_DB';
export const SUPER_DEALER_EMONEY_KIN_DB = 'SUPER_DEALER_EMONEY_KIN_DB';
export const REGULARISATION_SPECIALE = 'REGULARISATION_SPECIALE';
export const ALL_SUPER_DEALER_KIN_DB = 'ALL_SUPER_DEALER_KIN_DB';
export const ALL_CASH_COLLECTEUR_KIN_DB = 'ALL_CASH_COLLECTEUR_KIN_DB';
export const STOCK_A_REGULARISER = 'STOCK_A_REGULARISER';
export const TRAITEMENT_CONFIGURATION_MARCHAND_API =
    'TRAITEMENT_CONFIGURATION_MARCHAND_API';
export const CONFIGURATION_API_PARTENAIRE = 'CONFIGURATION_API_PARTENAIRE';
export const REPORT_STOCK_SUPER_DEALER = 'REPORT_STOCK_SUPER_DEALER';
export const REPORT_CAISSE_SUPER_DEALER = 'REPORT_CAISSE_SUPER_DEALER';
export const EVOLUTION_STOCK_SUPER_DEALER = 'EVOLUTION_STOCK_SUPER_DEALER';
export const EVOLUTION_CAISSE_SUPER_DEALER = 'EVOLUTION_CAISSE_SUPER_DEALER';
export const CAS_LITIGIEUX = 'CAS_LITIGIEUX';
export const LITIGIEUX_EMONEY = 'LITIGIEUX_EMONEY';
export const UPLOAD_RELEVE_EMONEY = 'UPLOAD_RELEVE_EMONEY';
export const UPLOAD_RELEVE_FLASH = 'UPLOAD_RELEVE_FLASH';
export const MARCHAND_KINDB = 'MARCHAND_KINDB';
export const ALL_MARCHAND = 'ALL_MARCHAND';
export const MARCHAND_ELECTRONIQUE = 'MARCHAND_ELECTRONIQUE';
export const CASH_COLLECTION_ORGANISATION = 'CASH_COLLECTION_ORGANISATION';
export const CREDITER_COMPTE_VIA_INSTITUT_FINANCIERE =
    'CREDITER_COMPTE_VIA_INSTITUT_FINANCIERE';
export const TRANSFERT_CAISSE = 'TRANSFERT_CAISSE';
export const OPERATION_CHANGE = 'OPERATION_CHANGE';
export const OPERATION_CHANGE_GUICHET = 'OPERATION_CHANGE_GUICHET';
export const ALL_COUPURE_DEVISE = 'ALL_COUPURE_DEVISE';
export const DEVISES = 'DEVISES';
export const VIREMENTS = 'VIREMENTS';
export const VIREMENTS_KINDB = 'VIREMENTS_KINDB';
export const COMPTE_BANCAIRE_PARTENAIRE = 'COMPTE_BANCAIRE_PARTENAIRE';
export const OPERATION_BANCAIRE = 'OPERATION_BANCAIRE';
export const VERSEMENTS = 'VERSEMENTS';
export const APPRO_CASH = 'APPRO_CASH';
export const ENCAISSEMENTS = 'ENCAISSEMENTS';
export const DECAISSEMENTS = 'DECAISSEMENTS';
export const USERS_ORGANISATION = 'USERS_ORGANISATION';
export const RAISONS = 'RAISONS';
export const DEPENSES = 'DEPENSES';
export const TAUX_SUPER_DEALER_KINDB = 'TAUX_SUPER_DEALER_KINDB';
export const ALL_SUPER_DEALER_KINDB_PARTENAIRE =
    'ALL_SUPER_DEALER_KINDB_PARTENAIRE';
export const INSTITUTION_AGREEE_NON_AGREEE = 'INSTITUTION_AGREEE_NON_AGREEE';
export const CONFIG_INSTRUCTION = 'CONFIG_INSTRUCTION';
export const COMPTE_PARALLELE = 'COMPTE_PARALLELE';
export const COMMANDE_EMONEY_RETRAIT = 'COMMANDE_EMONEY_RETRAIT';
export const COMMANDES_EMONEY_SENDER_ENCOURS =
    'COMMANDES_EMONEY_SENDER_ENCOURS';
export const COMMANDES_EMONEY_SENDER_ATTENTE =
    'COMMANDES_EMONEY_SENDER_ATTENTE';
export const COMMANDES_EMONEY_SENDER_ANNULER =
    'COMMANDES_EMONEY_SENDER_ANNULER';
export const COMMANDES_EMONEY_SENDER_LIVRER = 'COMMANDES_EMONEY_SENDER_LIVRER';
export const CAISSE_EMONEY = 'CAISSE_EMONEY';
export const ALL_CAISSE_ACTIVE_USER = 'ALL_CAISSE_ACTIVE_USER';
export const SOLDE_CAISSE_EMONEY = 'SOLDE_CAISSE_EMONEY';
export const REGULARISATION_CAISSE_EMONEY = 'REGULARISATION_CAISSE_EMONEY';
export const CAISSE_SUPER_DEALER_EMONEY = 'CAISSE_SUPER_DEALER_EMONEY';
export const ALL_CAISSE_SUPER_DEALER = 'ALL_CAISSE_SUPER_DEALER';
export const ALL_CAISSE_ORGANISATION = 'ALL_CAISSE_ORGANISATION';
export const IMPUTATION_CAISSE = 'IMPUTATION_CAISSE';
export const COMPTE_BANCAIRE_ORGANISATION_KINDB =
    'COMPTE_BANCAIRE_ORGANISATION_KINDB';
export const TUNNEL = 'TUNNEL';
export const STOCK_SUPER_DEALER = 'STOCK_SUPER_DEALER';
export const AGENT_SUPER_DEALER_KIN_DISTRIBUTION =
    'AGENT_SUPER_DEALER_KIN_DISTRIBUTION';
export const AGENT_CASH_COLLECTEUR = 'AGENT_CASH_COLLECTEUR';
export const DEPOT_EMONEY = 'DEPOT_EMONEY';
export const COMPTE_CREANCE = 'COMPTE_CREANCE';
export const STATUS_REVENDEUR = 'STATUS_REVENDEUR';
export const CASH_BOOK_USER = 'CASH_BOOK_USER';
export const CAISSE_CASH_COLLECTEUR = 'CAISSE_CASH_COLLECTEUR';
export const CAISSE_REVENDEUR = 'CAISSE_REVENDEUR';
export const TRANSFERT_KINDB = 'TRANSFERT_KINDB';
export const TRANSFERT_BALANCE_CAUTION = 'TRANSFERT_BALANCE_CAUTION';
export const BALANCE_AND_CAUTION_ORGANISATION =
    'BALANCE_AND_CAUTION_ORGANISATION';
export const VERSION_APPLICATION = 'VERSION_APPLICATION';
export const APPLICATION_MOBILE = 'APPLICATION_MOBILE';
export const TAUX_ORGANISATION_KINDISTRIBUTION =
    'TAUX_ORGANISATION_KINDISTRIBUTION';
export const ALL_BALANCE_ORGANISATION_COMPTE_TRANSFERT_FONDS =
    'ALL_BALANCE_ORGANISATION_COMPTE_TRANSFERT_FONDS';
export const TYPE_CAISSE_ORGANISATION = 'TYPE_CAISSE_ORGANISATION';
export const NOTIFICATION_MOBILE = 'NOTIFICATION_MOBILE';
export const PRODUITS_MARCHAND_SUPER_DEALER = 'PRODUITS_MARCHAND_SUPER_DEALER';
export const PRIX_PRODUIT_ELECTRONIQUE = 'PRIX_PRODUIT_ELECTRONIQUE';
export const COMPTE_BANCAIRE_SUPER_DEALER_KINDB =
    'COMPTE_BANCAIRE_SUPER_DEALER_KINDB';
export const SUJETS_DISPONIBLE = 'SUJETS_DISPONIBLE';
export const NOTIFICATION_PUSH = 'NOTIFICATION_PUSH';
export const FORMULAIRE_DYNAMIQUE_SIM_FLASH = 'FORMULAIRE_DYNAMIQUE_SIM_FLASH';
export const UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR =
    'UPLOAD_TRAITEMENT_PAIEMENT_VENDEUR';
export const CONFIGURATION_PARTENAIRE = 'CONFIGURATION_PARTENAIRE';
export const TAUX_ACTIFS = 'TAUX_ACTIFS';
export const RAPPORT_DASHBOARD_BALANCE = 'RAPPORT_DASHBOARD_BALANCE';
export const RAPPORT_DASHBOARD_CAISSE_EMONEY =
    'RAPPORT_DASHBOARD_CAISSE_EMONEY';
export const ETAT_CONFIGURATION_SENDER = 'ETAT_CONFIGURATION_SENDER';
export const HEURE_SUPPLEMENTAIRE = 'HEURE_SUPPLEMENTAIRE';
export const TACHES_PERFORMANCE_AGENT = 'TACHES_PERFORMANCE_AGENT';
export const ALL_TACHES_PERFORMANCE_AGENT = 'ALL_TACHES_PERFORMANCE_AGENT';
export const TACHES_PERFORMANCE_PROFIL_USER = 'TACHES_PERFORMANCE_PROFIL_USER';
export const OBJECTIFS = 'OBJECTIFS';
export const TACHES_PERFORMANCE = 'TACHES_PERFORMANCE';
export const ALL_FONCTION = 'ALL_FONCTION';
export const CONFIRMATION_SOLDE_COMPTE = `CONFIRMATION_SOLDE_COMPTE`;
export const FICHIER_PRESENCE = 'FICHIER_PRESENCE';
export const FONCTION_UPDATE = 'FONCTION_UPDATE';
export const RETRAIT_CREDITER_BALANCE_LOT = 'RETRAIT_CREDITER_BALANCE_LOT';
export const CREDITER_BALANCE_LOT = 'CREDITER_BALANCE_LOT';
export const SERVICE_CLIENT_FORM = `SERVICE_CLIENT_FORM`;
export const EMPLOYE_SERVICE_CLIENT = `EMPLOYE_SERVICE_CLIENT`;
export const TACHE_EXTERNE = `TACHE_EXTERNE`;
export const AUTORISATION_REFERENCE = `AUTORISATION_REFERENCE`;
export const DEMANDE_CONGE = `DEMANDE_CONGE`;
export const RAISON_INDISPONIBILITE = `RAISON_INDISPONIBILITE`;
export const MARCHAND_ELECTRONIQUE_SIM = `MARCHAND_ELECTRONIQUE_SIM`;
export const PRODUIT_SIM_MARCHAND = `PRODUIT_SIM_MARCHAND`;
export const STOCK_SIM_ORGANSITION = `STOCK_SIM_ORGANSITION`;
export const BROUILLON_CHARGE_VARIABLE = `BROUILLON_CHARGE_VARIABLE`;
export const BROUILLON_RESSOURCE = `BROUILLON_RESSOURCE`;
export const RETRAIT_A_AUTORISER = `RETRAIT_A_AUTORISER`;
