import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    FORM_CONST,
    TODAY,
    TYPE_ORGANISATION,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import {
    URL_CONST,
    agregationMouvement,
    traitementMouvement,
    useHasService
} from '../../../util';
import {
    SimpleCardMouvement,
    ReportCaisseOrganisationSelector
} from '../../../components';
import { MainReportComponent } from '../releve/generic';
import { REDUX_NODE_NAME } from '../../../redux';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isDisplayChart: false,
    selectedCaisse: {},
    selectedTypeCaisse: {},
    selectedCompte: {},
    selectedSuperDealerIsAll: {},
    selectedTypeOrganisation: {},
    selectedOrganisation: {},
    isFetching: false,
    aggregated: {},
    devise: '',
    chartTitle: '',
    yAxisTittle: '',
    fetchedDevise: '',
    fetchedCaisse: ''
};

const mapper = [
    {
        field: 'valeurTypeOrganisation.designation',
        displayName: 'Organisation'
    },
    {
        displayName: 'Libelle',
        dataExtractor: (item) =>
            item?.libelle ? item?.libelle || '' : item?.typeEntite || ''
    },
    {
        field: 'soldePrecedent',
        displayName: 'Solde Ouverture'
    },
    {
        field: 'entree',
        displayName: 'Entree'
    },
    {
        field: 'sortie',
        displayName: 'Sortie'
    },
    {
        field: 'soldeActuel',
        displayName: 'Solde Fermeture'
    },
    {
        field: 'dateCreation',
        displayName: 'date création'
    }
];

const setDefaultProperties = ({ type, service, isKinDB }) => {
    const payload = {
        caisseNodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_USER,
        caisseReduxProperty: 'caissesActiveUser'
        // superDealerNodeName: REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB,
        // superDealerReduxProperty: 'allSuperDealerKinDB'
    };
    const conditions = {};

    if (
        type === TYPE_ORGANISATION.NAPP &&
        (service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT.code
        ] ||
            service[
                CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                    .INITIER_DECAISSEMENT.code
            ])
    ) {
        payload.caisses = {
            estOrganisation: false
        };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_ACTIVE_USER;
        payload.caisseReduxProperty = 'allCaissesActiveUser';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isCaissier = true;
    }

    /** Autres que le caissier */
    if (
        type === TYPE_ORGANISATION.NAPP &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT.code
        ] &&
        !service[
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT.code
        ]
    ) {
        payload.caisses = {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            estAll: true
        };
        payload.caisseNapp = {
            estOrganisation: true,
            estOperation: true
        };
        payload.caisseNodeName = REDUX_NODE_NAME.ALL_CAISSE_SUPER_DEALER;
        payload.caisseReduxProperty = 'allCaissesSuperDealer';
        payload.caisseOrganisationNodeName =
            REDUX_NODE_NAME.ALL_CAISSE_ORGANISATION;
        payload.caisseOrganisationReduxProperty = 'allCaissesOrganisation';
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        conditions.isAll = true;
        /** Is Global pour la condition des superDealers pour les nons caissiers */
        conditions.isGlobal = true;
    }
    if (type === TYPE_ORGANISATION.NAPP) {
        return {
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.REVENDEUR) {
        payload.superDealerNodeName = REDUX_NODE_NAME.ALL_SUPER_DEALER_KIN_DB;
        payload.superDealerReduxProperty = 'allSuperDealerKinDB';
        payload.superDealer = {
            typeEntite: type,
            estAll: true
        };
        return {
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            cfPayload: payload,
            cfCondition: conditions
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER) {
        if (isKinDB) {
            conditions.isAll = true;
            payload.caisses = { estOrganisation: false };
        }
        return {
            cfPayload: payload,
            cfCondition: conditions
        };
    }
    return {
        cfPayload: {},
        cfCondition: {}
    };
};

export function ReleveCaisseForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser, organisation } = useSelector(
        (reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            nappUser: reduxState.nappUser,
            organisation: reduxState.organisation
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_TRANSFERT_CAISSE_KINDB,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_ENCAISSEMENT,
            CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_FINANCE
                .INITIER_DECAISSEMENT
        ]
    });
    /**
     * Common form processes
     */
    const { idToken, typeEntite } = useGetUserDetail({
        firebaseUser,
        nappUser,
        organisation
    });

    const { cfPayload, cfCondition } = setDefaultProperties({
        type: typeEntite,
        service: hasService,
        isKinDB: organisation.estKinDistribution
    });

    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const mouvementsFormater = (listMouvement) =>
        traitementMouvement(listMouvement).sort(
            (a, b) => b.dateCreation - a.dateCreation
        );
    const { fields, elements } = formState;
    const fetchOnSuccess = ({ element }) => {
        const agregees = agregationMouvement(element || []);
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'aggregated',
                data: agregees
            }
        });
    };
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedCaisse } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const devise = selectedCaisse?.designation?.split('-').reverse()?.[0];
        const fetchedCaisse = selectedCaisse?.designation;
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'fetchedDevise',
                data: devise
            }
        });
        formDispatcher({
            type: FORM_CONST.FIELD_CHANGE,
            payload: {
                field: 'fetchedCaisse',
                data: fetchedCaisse
            }
        });

        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload: {
                    valeurTypeProprietaire: selectedCaisse?.id,
                    typeProprietaire: DB_NODE.CAISSE,
                    dateDebut,
                    dateFin
                },
                functionName: URL_CONST.GET_LIST_MOUVEMENT_PLAGE,
                etat: elements.mouvements.status,
                formDispatcher,
                formater: mouvementsFormater,
                onSuccess: fetchOnSuccess,
                reduxDispatcher
            })
        );
    };
    function cardTotaux() {
        const { aggregated, fetchedDevise, fetchedCaisse } = fields;
        if (formState?.elements?.mouvements?.value?.length) {
            return (
                <SimpleCardMouvement
                    entree={aggregated?.entree}
                    sortie={aggregated?.sortie}
                    fermeture={aggregated?.fermeture}
                    ouverture={aggregated?.ouverture}
                    devise={fetchedDevise}
                    textAlternatif={fetchedCaisse}
                    nombreApresVirgule={0}
                />
            );
        }
        return null;
    }
    return (
        <MainReportComponent
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={mapper}
            dataList={elements.mouvements?.value || []}
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
            topOfChart={() => cardTotaux()}
        >
            <ReportCaisseOrganisationSelector
                formState={formState}
                formDispatcher={formDispatcher}
                cfPayload={cfPayload}
                cfCondition={cfCondition}
                idToken={idToken}
                typeEntite={typeEntite}
            />
        </MainReportComponent>
    );
}
