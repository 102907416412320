import React, { useCallback, useMemo } from 'react';
import {
    submitPostAction,
    useFetchAndListening,
    useFormGlobalReducer
} from '@napp-inc/jnapp-hook';
import {
    Array,
    DB_NODE,
    FORM_CONST,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { formaterCaisses, URL_CONST, useDebounce } from '../../../../util';
import { REDUX_NODE_NAME } from '../../../../redux';
import {
    ConditionalRenderingWrapper,
    CustomButtonLoader,
    CustomCol,
    CustomDropdown,
    CustomInput,
    CustomRow,
    CustomSwitchButton,
    FormWrapper
} from '../../../../components';

const superDealerNumero = (SD = []) => {
    const sdFinal = [];
    SD.forEach((element) => {
        const typeBusiness = element?.typeBusiness;
        if (typeBusiness) {
            typeBusiness.forEach((tb) => {
                const existes = sdFinal.find(
                    (sdf) => sdf?.numero === tb?.numero
                );
                if (!existes)
                    sdFinal.push({
                        designation: element?.entreprise?.designation,
                        numero: tb?.numero,
                        code: tb?.code,
                        codeSuperDealer: element?.entreprise?.code
                    });
            });
        }
    });
    return sdFinal;
};

export function FacturationStock({
    idToken,
    typeEntite,
    uiValidator,
    initialState,
    cfPayload,
    stockBySuperDealerFn,
    getCompteSuperDealerFn
}) {
    const reduxDispatcher = useDispatch();
    const {
        organisation,
        comptes,
        caisses,
        etatCaisses,
        etatComptes,
        numeroSuperDealerKinDB,
        superDealerKinDB,
        etatSuperDealerKinDB,
        institutionsFinanciere,
        etatInstitutionsFinanciere,
        comptesSuperDealerKinDB,
        etatComptesSuperDealerKinDB
    } = useSelector((reduxState) => ({
        organisation: reduxState.organisation,
        comptes: reduxState.comptes,
        comptesSuperDealerKinDB: reduxState.comptesSuperDealerKinDB,
        etatComptesSuperDealerKinDB:
            reduxState.etat.comptesSuperDealerKinDB.etat,
        caisses: formaterCaisses(reduxState.caissesActiveOrganisation || []),
        etatCaisses: reduxState.etat.caissesActiveOrganisation.etat,
        etatComptes: reduxState.etat.comptes.etat,
        numeroSuperDealerKinDB: superDealerNumero(
            reduxState.superDealerKinDB
                .filter((item) => !item?.estEmoney)
                .map((item) => ({
                    ...item,
                    code: item.entreprise.code,
                    designation: item.entreprise.designation
                }))
        ),
        superDealerKinDB: reduxState.superDealerKinDB
            .filter((item) => !item?.estEmoney)
            .map((item) => ({
                ...item,
                code: item.entreprise.code,
                designation: item.entreprise.designation
            })),
        etatSuperDealerKinDB: reduxState.etat.superDealerKinDB.etat,
        institutionsFinanciere: reduxState.institutionsFinanciere,
        etatInstitutionsFinanciere: reduxState.etat.institutionsFinanciere.etat
    }));

    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );

    const compteFormater = (tableau = []) =>
        tableau?.map((item) => ({
            ...item,
            id: item.id,
            code: item.numero,
            designation: `${item.numero} - ${item.devise}`
        }));

    /**
     * Recupération des données nécessaires
     */

    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
                payload: cfPayload?.caisses,
                nodeName: REDUX_NODE_NAME.CAISSE_ACTIVE_ORGANISATION,
                etat: etatCaisses
                // formater: caisseFormater
                // isCondition: typeEntite === TYPE_ORGANISATION.SUPER_DEALER
            },
            {
                isOnlyFetch: true,
                nodeName: DB_NODE.COMPTE,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatComptes,
                formater: compteFormater
            },
            {
                isOnlyFetch: true,
                nodeName: REDUX_NODE_NAME.COMPTE_BANCAIRE_SUPER_DEALER_KINDB,
                functionName: URL_CONST.GET_LIST_COMPTE_BANCAIRE_ORGANISATION,
                etat: etatComptes,
                formater: compteFormater,
                payload: cfPayload?.comptes,
                isCondition:
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                    (typeEntite === TYPE_ORGANISATION.REVENDEUR &&
                        !!organisation.estDistributeur)
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_SUPER_DEALER_KIN_DISTRIBUTION,
                nodeName: REDUX_NODE_NAME.SUPER_DEALER_KIN_DB,
                payload: cfPayload?.superdealers,
                etat: etatSuperDealerKinDB,
                isCondition:
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                    (typeEntite === TYPE_ORGANISATION.REVENDEUR &&
                        !!organisation.estDistributeur)
            },
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_BANQUE,
                nodeName: DB_NODE.INSTITUTION_FINANCIERE,
                etat: etatInstitutionsFinanciere
            }
        ]
    });
    const { fields, form, elements } = formState;
    /**
     * Debounce Caisses
     */
    useDebounce({
        name: 'selectedSuperDealer',
        preview: 'previewValue',
        propertyInName: 'code',
        isObject: true,
        formDispatcher,
        formState,
        delay: 500,
        idToken,
        functionName: URL_CONST.GET_LIST_CAISSE_ACTIVE_USER,
        elementPropertyName: 'caisses',
        payloadToAdd: {
            estOrganisation: true,
            typeProprietaire: 'SuperDealer',
            codeValeurTypeProprietaire: fields.selectedSuperDealer.code,
            estEmoney: false
        },
        dataLength: 0,
        condition:
            fields.selectedTypeStock.code === 'FLASH' &&
            fields.selectedMoyenPaiement.code === 'Caisse'
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            newApprovisionnementStock: {
                typeEntite: fields.selectedMoyenPaiement.code
            }
        };
        if (typeEntite === TYPE_ORGANISATION.SUPER_DEALER) {
            payload.estSuperDealer = true;
        }
        const numToUse = organisation.typeBusiness
            ? superDealerNumero([organisation])
            : numeroSuperDealerKinDB;
        if (fields.selectedTypeStock.code === 'SIM') {
            const code = superDealerKinDB?.find(
                (item) => item.code === fields.selectedSuperDealer?.code
            )?.marchand?.code;
            payload.newApprovisionnementStock.estSim = true;
            payload.newApprovisionnementStock.marchand = {
                code
            };
            // payload.codeSuperDealer = fields.selectedSuperDealer.code;
        }
        if (
            fields.selectedNumeroStock.numero &&
            !fields.numero &&
            fields.selectedTypeStock.code === 'FLASH'
        ) {
            const finded = numToUse.find(
                (item) => item?.numero === fields?.selectedNumeroStock?.numero
            );
            payload.newApprovisionnementStock.numero =
                fields.selectedNumeroStock?.numero;
            payload.codeSuperDealer = finded.codeSuperDealer;
        }
        if (fields?.numero && !fields.selectedNumeroStock?.numero) {
            payload.newApprovisionnementStock.numero = fields?.numero;
        }
        if (fields.selectedMoyenPaiement.code === 'Caisse') {
            payload.newApprovisionnementStock.valeurTypeEntite = {
                reference: fields.selectedCaisse.id
            };
        }
        if (fields.selectedMoyenPaiement.code === 'Compte') {
            payload.newApprovisionnementStock.valeurTypeEntite = {
                reference: fields.selectedCompte.id
            };
        }
        if (fields.selectedTypeValeur.code === 'Montant') {
            payload.newApprovisionnementStock.montant = fields.montant;
        }
        if (fields.selectedTypeValeur.code === 'Quantité') {
            payload.newApprovisionnementStock.quantite = fields.quantite;
        }
        if (fields.selectedMoyenPaiement.code === 'Compte') {
            payload.newApprovisionnementStock.estTransfertFonds =
                fields.estTransfertFonds;
        }
        // console.log(payload);
        // console.log(formState);
        formDispatcher(
            submitPostAction({
                idToken,
                functionName: URL_CONST.POST_APPRO_STOCK,
                payload,
                fields,
                reduxNodeName: DB_NODE.APPROVISIONNEMENT_STOCK,
                reduxDispatcher,
                uiValidator
            })
        );
    };
    const { stock, hasLength } = useMemo(
        () =>
            stockBySuperDealerFn({
                choosenSuperDealer:
                    typeEntite === TYPE_ORGANISATION.SUPER_DEALER
                        ? organisation.entreprise
                        : fields.selectedSuperDealer,
                numbers:
                    typeEntite === TYPE_ORGANISATION.SUPER_DEALER &&
                    organisation.typeBusiness
                        ? superDealerNumero([organisation])
                        : numeroSuperDealerKinDB
            }),
        [
            fields.selectedSuperDealer,
            numeroSuperDealerKinDB,
            organisation,
            stockBySuperDealerFn,
            typeEntite
        ]
    );

    const compteSuperDealer = useMemo(
        () =>
            getCompteSuperDealerFn({
                isTrue:
                    typeEntite !== TYPE_ORGANISATION.SUPER_DEALER &&
                    fields.selectedTypeStock?.code === 'FLASH',
                accounts:
                    typeEntite !== TYPE_ORGANISATION.SUPER_DEALER &&
                    fields.selectedTypeStock?.code === 'FLASH'
                        ? comptesSuperDealerKinDB
                        : comptes,
                bank: fields.selectedInstitutionFinanciere,
                selectedSuperDealer: fields.selectedSuperDealer
            }),
        [
            comptes,
            comptesSuperDealerKinDB,
            fields.selectedInstitutionFinanciere,
            fields.selectedSuperDealer,
            fields.selectedTypeStock?.code,
            getCompteSuperDealerFn,
            typeEntite
        ]
    );

    const groupeCaisses = ({ data }) => {
        const groupedData = Array.groupByProperty({
            array: data || [],
            property: 'valeurTypeEntite.designation'
        });

        const keys = Object.keys(groupedData || {}).map((item) => ({
            code: item,
            designation: item
        }));
        return { keys, groupedData };
    };

    const caissesByKeys = useCallback(() => {
        const { groupedData } = groupeCaisses({ data: caisses });
        const data =
            groupedData?.[fields?.selectedCaisseOrganisation?.code] || [];
        if (fields?.selectedType?.code === `EMONEY`) {
            return data.filter((item) => !!item.estEmoney);
        }
        if (fields?.selectedType?.code === `ESPECE`) {
            return data.filter((item) => !item.estEmoney);
        }
        return data;
    }, [
        caisses,
        fields?.selectedCaisseOrganisation?.code,
        fields?.selectedType?.code
    ]);

    const getCaissesByKeys = caissesByKeys();

    const renderContent = () => (
        <FormWrapper
            formState={formState}
            formDispatcher={formDispatcher}
            listDependencies={[
                {
                    dependency: 'caisses',
                    etat: etatCaisses
                },
                {
                    dependency: 'comptesBancaire',
                    etat: etatComptes
                },
                {
                    dependency: 'superDealerKinDB',
                    etat: etatSuperDealerKinDB,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.NAPP ||
                        typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                        (typeEntite === TYPE_ORGANISATION.REVENDEUR &&
                            !!organisation.estDistributeur)
                },
                {
                    dependency: 'comptesBancaireSuperDealer',
                    etat: etatComptesSuperDealerKinDB,
                    isCondition:
                        typeEntite === TYPE_ORGANISATION.NAPP ||
                        typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                        (typeEntite === TYPE_ORGANISATION.REVENDEUR &&
                            !!organisation.estDistributeur)
                },
                {
                    dependency: 'banques',
                    etat: etatInstitutionsFinanciere
                }
            ]}
        >
            <CustomRow>
                <CustomDropdown
                    label="Type Stock* :"
                    options={[{ code: 'FLASH' }, { code: 'SIM' }]}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Sélectionner ..."
                    formDispatcher={formDispatcher}
                    name="selectedTypeStock"
                    id="facturationselectedTypeStockId"
                    propertyToDisplay="code"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow>
                <CustomDropdown
                    label="Type Facturaction* :"
                    options={[{ code: 'Compte' }, { code: 'Caisse' }]}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Sélectionner ..."
                    formDispatcher={formDispatcher}
                    name="selectedMoyenPaiement"
                    id="facturationselectedMoyenPaiementId"
                    propertyToDisplay="code"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </CustomRow>
            <CustomRow
                isShouldBeRendered={
                    typeEntite === TYPE_ORGANISATION.NAPP ||
                    typeEntite === TYPE_ORGANISATION.DISTRIBUTEUR ||
                    (typeEntite === TYPE_ORGANISATION.REVENDEUR &&
                        !!organisation.estDistributeur)
                }
            >
                <CustomDropdown
                    label="Mes Organisations* :"
                    options={superDealerKinDB}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Sélectionner ..."
                    formDispatcher={formDispatcher}
                    name="selectedSuperDealer"
                    id="facturationSelectedSuperDealerId"
                    formState={formState}
                    uiValidator={uiValidator}
                    disabled={!fields.selectedMoyenPaiement.code}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={fields?.selectedTypeStock?.code === 'FLASH'}
            >
                <CustomRow>
                    <CustomDropdown
                        label="Stock* :"
                        options={stock}
                        propertyToDisplay="designation"
                        idProperty="numero"
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        defaultOption="Sélectionner ..."
                        formDispatcher={formDispatcher}
                        name="selectedNumeroStock"
                        id="facturationSelectedNumeroStockId"
                        formState={formState}
                        uiValidator={uiValidator}
                        disabled={!hasLength}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedMoyenPaiement.code === 'Compte'
                }
            >
                <CustomRow>
                    <CustomDropdown
                        idProperty="code"
                        propertyToDisplay="designation"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        label="Institution Financière* : "
                        name="selectedInstitutionFinanciere"
                        id="creationRemboursementCautionCompteInstitutFinanciereId"
                        options={institutionsFinanciere}
                        selectionClassName="form-select-md"
                        defaultOption="Selectionner Institution Financière"
                        divClassName="col-sm-10"
                        labelClassName="col-sm-2"
                    />
                </CustomRow>
                <CustomRow>
                    <CustomDropdown
                        idProperty="id"
                        label="Compte à Débiter* : "
                        propertyToDisplay="designation"
                        options={compteSuperDealer}
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        defaultOption="Sélectionner"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        name="selectedCompte"
                        id="idCompte"
                        formState={formState}
                        disabled={!compteSuperDealer?.length}
                    />
                </CustomRow>
                <CustomSwitchButton
                    id="creationFacturationEstTransfertfondsId"
                    text="Générer un transfert de fonds?"
                    inputTitle="Génère automatiquement un transfert de fonds"
                    formDispatcher={formDispatcher}
                    name="estTransfertFonds"
                    divClassName="col-md-8 mb-3"
                    formState={formState}
                    uiValidator={uiValidator}
                />
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedMoyenPaiement.code === 'Caisse'
                }
            >
                <CustomRow
                    isShouldBeRendered={
                        (fields?.selectedTypeStock?.code === 'SIM' ||
                            typeEntite === TYPE_ORGANISATION.SUPER_DEALER) &&
                        typeEntite !== TYPE_ORGANISATION.REVENDEUR
                    }
                >
                    <CustomDropdown
                        idProperty="id"
                        propertyToDisplay="designation"
                        formDispatcher={formDispatcher}
                        formState={formState}
                        uiValidator={uiValidator}
                        label="Caisse* : "
                        name="selectedCaisse"
                        id="creationFacturationselectedCaisseId"
                        options={caisses}
                        selectionClassName="form-select-md"
                        defaultOption="Selectionner Caisse"
                        divClassName="col-sm-10 col-12"
                        labelClassName="col-sm-2 col-12"
                    />
                </CustomRow>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        typeEntite === TYPE_ORGANISATION.REVENDEUR
                    }
                >
                    <CustomRow>
                        <CustomDropdown
                            propertyToDisplay="designation"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            label="Propriétaire Caisse* : "
                            name="selectedCaisseOrganisation"
                            id="creationFacturationselectedCaisseOrganisationId"
                            options={
                                groupeCaisses({ data: caisses })?.keys || []
                            }
                            selectionClassName="form-select-md"
                            defaultOption="Selectionner Organisation"
                            divClassName="col-sm-10 col-12"
                            labelClassName="col-sm-2 col-12"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            propertyToDisplay="designation"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            label="Type Caisse* : "
                            name="selectedType"
                            id="creationFacturationselectedTypeId"
                            options={[
                                { code: `EMONEY`, designation: `E-Money` },
                                { code: `ESPECE`, designation: `Espèce` }
                            ]}
                            selectionClassName="form-select-md"
                            defaultOption="Selectionner Type Caisse"
                            divClassName="col-sm-10 col-12"
                            labelClassName="col-sm-2 col-12"
                        />
                    </CustomRow>
                    <CustomRow>
                        <CustomDropdown
                            idProperty="id"
                            propertyToDisplay="designation"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            label="Caisse* : "
                            name="selectedCaisse"
                            id="creationFacturationselectedCaisseId"
                            options={getCaissesByKeys}
                            disabled={!getCaissesByKeys.length}
                            selectionClassName="form-select-md"
                            defaultOption="Selectionner Caisse"
                            divClassName="col-sm-10 col-12"
                            labelClassName="col-sm-2 col-12"
                        />
                    </CustomRow>
                </ConditionalRenderingWrapper>
                <ConditionalRenderingWrapper
                    isShouldBeRendered={
                        fields?.selectedTypeStock?.code === 'FLASH' &&
                        typeEntite !== TYPE_ORGANISATION.SUPER_DEALER &&
                        typeEntite !== TYPE_ORGANISATION.REVENDEUR
                    }
                >
                    {elements?.caisses?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS ? (
                        <CustomRow>
                            <p className="d-flex justify-content-end mt-2 placeholder-glow w-100">
                                <span className="placeholder col-sm-1" />
                                <span className="col-sm-1" />
                                <span className="placeholder col-10" />
                            </p>
                        </CustomRow>
                    ) : null}
                    <CustomRow
                        isShouldBeRendered={
                            elements?.caisses?.status ===
                            FORM_CONST.FETCH_ELEMENT_SUCCESS
                        }
                    >
                        <CustomDropdown
                            idProperty="id"
                            propertyToDisplay="designation"
                            formDispatcher={formDispatcher}
                            formState={formState}
                            uiValidator={uiValidator}
                            label="Caisse* : "
                            name="selectedCaisse"
                            id="creationFacturationselectedCaisseId"
                            options={formaterCaisses(
                                elements?.caisses?.value || []
                            )}
                            selectionClassName="form-select-md"
                            defaultOption="Selectionner Caisse"
                            divClassName="col-sm-10"
                            labelClassName="col-sm-2"
                            disabled={!elements?.caisses?.value?.length}
                        />
                    </CustomRow>
                </ConditionalRenderingWrapper>
            </ConditionalRenderingWrapper>
            <CustomRow>
                <CustomDropdown
                    label="Valeur* : "
                    options={[
                        { code: 'Quantité', designation: 'En Quantité' },
                        { code: 'Montant', designation: 'En Montant' }
                    ]}
                    labelClassName="col-12 col-sm-2"
                    divClassName="col-12 col-sm-10"
                    defaultOption="Sélectionner ..."
                    formDispatcher={formDispatcher}
                    uiValidator={uiValidator}
                    name="selectedTypeValeur"
                    id="facturationSelectedTypeValeurId"
                    formState={formState}
                />
            </CustomRow>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeValeur?.code === 'Montant'
                }
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        label="Montant* : "
                        placeholder="Montant"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        name="montant"
                        type="number"
                        id="idMontantFacturation"
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper
                isShouldBeRendered={
                    fields.selectedTypeValeur?.code === 'Quantité'
                }
            >
                <CustomRow>
                    <CustomInput
                        isInputGroup={false}
                        labelClassName="col-12 col-sm-2"
                        divClassName="col-12 col-sm-10"
                        label="Quantité* :"
                        placeholder="Quantite"
                        formDispatcher={formDispatcher}
                        uiValidator={uiValidator}
                        name="quantite"
                        type="number"
                        id="idQuantiteUniteFacturation"
                        formState={formState}
                    />
                </CustomRow>
            </ConditionalRenderingWrapper>
            <CustomRow className="row">
                <CustomCol className="col-3">
                    <CustomButtonLoader
                        className="btn-success"
                        onClick={handleSubmit}
                        text="Sauvegarder"
                        disabled={form.status === FORM_CONST.CREATION_EN_COURS}
                    />
                </CustomCol>
            </CustomRow>
        </FormWrapper>
    );
    return renderContent();
}
