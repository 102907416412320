import { CATEGORIE_MARCHAND, GENERIC_ACTIONS_TYPE } from '@napp-inc/jnapp-util';
import { useFetchAndListening, useGetUserDetail } from '@napp-inc/jnapp-hook';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDropdown } from '../dropdown';
import { CenteredSpinner } from '../placeholder';
import { URL_CONST } from '../../util';
import { REDUX_NODE_NAME } from '../../redux';
import { CustomAlert } from '../alert';

/**
 * Construit une dropdown où l'on peut sélectionner les marchands
 * @param {Object} param0
 * @param {Function} param0.formDispatcher form dispatcher du parent
 * @param {Object} param0.formState form state du parent
 * @param {String} param0.label Label du dropdown, par défaut "Caisse : "
 * @param {String} param0.propertyName propriété où la valeur de la caisse selectionnée est enregistrée dans le form state, par defaut "selectedCaisse"
 * @param {String} param0.labelClassName className du label
 * @param {String} param0.divClassName className du div
 * @param {String} param0.propertyToDisplay nom de la propriété à afficher dans le dropdown, par défaut : "designation"
 * @param {String} param0.idProperty propriété id à utiliser, par défaut : "id"
 * @returns {React.Component}
 */
export function MarchandSelector({
    formDispatcher,
    formState,
    name = 'selectedMarchand',
    estNonEmoney = false,
    estNonAirtime = false,
    idProperty = 'id',
    isAddAditionalOptions = false,
    additionnalOptions = [{ id: 'all', code: 'all', designation: 'Tous' }]
}) {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, marchandsElectronique, etatMarchandElectronique } =
        useSelector((reduxState) => ({
            firebaseUser: reduxState.firebaseUser,
            marchandsElectronique: reduxState.marchandsElectronique.filter(
                (item) => {
                    if (
                        estNonEmoney &&
                        item?.categorieMarchand?.code ===
                            CATEGORIE_MARCHAND.MOBILE_MONEY.code
                    )
                        return false;
                    if (
                        estNonAirtime &&
                        item?.categorieMarchand?.code ===
                            CATEGORIE_MARCHAND.AIRTIME.code
                    )
                        return false;
                    return true;
                }
            ),
            etatMarchandElectronique: reduxState.etat.marchandsElectronique.etat
        }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    useFetchAndListening({
        idToken,
        reduxDispatcher,
        list: [
            {
                isOnlyFetch: true,
                functionName: URL_CONST.GET_LIST_MARCHAND_ELECTRONIQUE,
                nodeName: REDUX_NODE_NAME.MARCHAND_ELECTRONIQUE,
                etat: etatMarchandElectronique
                // payload: { codeCategorieParent: '' }
            }
        ]
    });

    const renderContent = () => {
        if (
            etatMarchandElectronique ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_SUCCESS
        ) {
            return (
                <CustomDropdown
                    options={marchandsElectronique}
                    propertyToDisplay="designation"
                    label="Marchand"
                    labelClassName="col-sm-2"
                    divClassName="col-sm-12"
                    defaultOption="Sélectionner un Marchand"
                    formDispatcher={formDispatcher}
                    name={name}
                    id="creationUploadFichierEmoneyInstitutionFinanciere"
                    formState={formState}
                    idProperty={idProperty}
                    keyProperty="id"
                    additionnalOptions={
                        isAddAditionalOptions ? additionnalOptions : []
                    }
                />
            );
        }
        if (
            etatMarchandElectronique ===
            GENERIC_ACTIONS_TYPE.CHARGEMENT_ITEM_ECHEC
        ) {
            return (
                <CustomAlert error="une erreur est survenue lors du chargement des marchands" />
            );
        }
        return <CenteredSpinner />;
    };
    return renderContent();
}
