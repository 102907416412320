import {
    fromTimestampToString,
    resolvePathPropertyOfObject
} from '@napp-inc/jnapp-util';
import { useContext, useEffect, useState } from 'react';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';
import { StateContext } from '../../context/state-context';

export function CustomListReferenceGroupSelection({
    data,
    fields,
    property,
    handleChange,
    mapper
}) {
    const { screenSize, setScreenSize } = useContext(StateContext);
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [screenSize, setScreenSize]);

    useEffect(() => {
        if (screenSize <= 900) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [screenSize]);

    const contentToRender = ({ dataProps = [], isMobileProps = false }) => {
        if (!dataProps.length)
            return (
                <Container className="mt-5">
                    <Row className="justify-content-center">
                        <Col xs={12} md={8} lg={6}>
                            <Alert variant="info" className="text-center">
                                <h4>Aucune donnée à afficher</h4>
                                <p>
                                    Il n&apos;y a actuellement aucune donnée à
                                    afficher. Veuillez réessayer plus tard.
                                </p>
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            );
        if (dataProps.length) {
            if (!isMobileProps) {
                return dataProps.map((item) => (
                    <ul
                        key={item?.id}
                        className="list-group list-group-horizontal mb-3 col-12 bg-primary"
                    >
                        <li className="list-group-item d-flex align-items-center col-1">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                id={item?.id}
                                checked={
                                    !!fields?.[property]?.find(
                                        (element) => element === item?.id
                                    )
                                }
                                onChange={handleChange}
                            />
                        </li>
                        {mapper.map((element) => (
                            <li key={element.path} className={element.liClass}>
                                <div className={element.divClass}>
                                    <h5 className={element.hClass}>
                                        {element.hTitle}
                                    </h5>
                                </div>
                                <p className={element.pClass}>
                                    <span>
                                        {element?.path?.includes('date')
                                            ? fromTimestampToString(
                                                  resolvePathPropertyOfObject(
                                                      element.path,
                                                      item
                                                  )
                                              )
                                            : resolvePathPropertyOfObject(
                                                  element.path,
                                                  item
                                              )}
                                    </span>{' '}
                                    {element.smallPath ? (
                                        <small className={element.smallClass}>
                                            {item?.devise}
                                        </small>
                                    ) : null}
                                </p>
                            </li>
                        ))}
                    </ul>
                ));
            }
            if (isMobileProps) {
                return dataProps.map((item) => (
                    <Card key={item?.id} className="mb-3">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3 border-bottom pb-1">
                                <input
                                    className="form-check-input me-1"
                                    type="checkbox"
                                    id={item?.id}
                                    checked={
                                        !!fields?.[property]?.find(
                                            (element) => element === item?.id
                                        )
                                    }
                                    onChange={handleChange}
                                />
                                <Card.Title>
                                    {resolvePathPropertyOfObject(
                                        mapper.find(
                                            (mapperItem) =>
                                                mapperItem.isMobileTitle
                                        ).path,
                                        item
                                    )}
                                </Card.Title>
                            </div>
                            {/* Contenu sous format Titre: Valeur */}
                            <div>
                                {mapper
                                    .filter((el) => !el.isMobileTitle)
                                    .map((dt) => (
                                        <>
                                            <span
                                                className="mb-2"
                                                key={JSON.stringify(dt)}
                                            >
                                                <strong>{dt.hTitle}:</strong>
                                                <br />
                                                {dt?.path?.includes('date')
                                                    ? fromTimestampToString(
                                                          resolvePathPropertyOfObject(
                                                              dt.path,
                                                              item
                                                          )
                                                      )
                                                    : resolvePathPropertyOfObject(
                                                          dt.path,
                                                          item
                                                      )}
                                                <span className="ms-2">
                                                    {dt.smallPath ? (
                                                        <small
                                                            className={
                                                                dt.smallClass
                                                            }
                                                        >
                                                            {item?.devise}
                                                        </small>
                                                    ) : null}
                                                </span>
                                            </span>
                                            <br />
                                        </>
                                    ))}
                            </div>
                        </Card.Body>
                    </Card>
                ));
            }
        }
        return null;
    };

    return contentToRender({ dataProps: data, isMobileProps: isMobile });
}
