import { CODE_SERVICE } from '@napp-inc/jnapp-util';
import { Vente } from '../../../module';
import {
    ClientCreateRelPath,
    ClientDetailRelPath,
    ClientViewLayoutRelPath,
    CommandeEmoneySenderDetailRelPath,
    CommandeEmoneySenderViewLayoutRelPath,
    CommandeFlashCreateRelPath,
    CommandeFlashDetailRelPath,
    CommandeFlashSenderDetailRelPath,
    CommandeFlashSenderViewLayoutRelPath,
    CommandeFlashViewLayoutRelPath,
    CommandeRetraitEmoneySenderViewLayoutRelPath,
    DepotEmoneyCreateRelPath,
    DepotEmoneyDetailRelPath,
    DepotEmoneyViewLayoutRelPath,
    RecouvrementEmoneyDetailRelPath,
    RecouvrementEmoneyViewLayoutRelPath,
    RetraitCashDetailRelPath,
    RetraitCashViewLayoutRelPath
} from './vente-rel-path';

/**
 * @desc Client organisation
 */

export const ClientViewLayoutPath = {
    ...ClientViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.ClientViewLayout,
    element: <Vente.ClientViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .DECLARER_NUMERO_ABONNE_MARCHAND,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_CLIENT
    ]
};

export const ClientCreatePath = {
    ...ClientCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CreationClientForm,
    element: <Vente.CreationClientForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .DECLARER_NUMERO_ABONNE_MARCHAND
    ]
};

export const ClientDetailPath = {
    ...ClientDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailClientOrganisationForm,
    element: <Vente.DetailClientOrganisationForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_VENTE
            .DECLARER_NUMERO_ABONNE_MARCHAND,
        CODE_SERVICE.SERVICE_CODE_REVENDEUR.CODE_SERVICE_MODULE_ADMINISTRATION
            .LECTURE_LIST_CLIENT
    ]
};

/**
 * @desc Commande flash
 */

export const CommandeFlashViewLayoutPath = {
    ...CommandeFlashViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CommandeFlashViewLayout,
    element: <Vente.CommandeFlashViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH,
        CODE_SERVICE.VENTE_ORGANISATION.LECTURE_COMMANDE_FLASH_BY_ORGANISATION,
        CODE_SERVICE.VENTE_ORGANISATION.LECTURE_COMMANDE_FLASH_BY_UID,
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH_MODE_RECOUVREMENT
    ]
};

export const CommandeFlashCreatePath = {
    ...CommandeFlashCreateRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CreationCommandeFlashForm,
    element: <Vente.CreationCommandeFlashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH,
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH_MODE_RECOUVREMENT
    ]
};

export const CommandeFlashDetailPath = {
    ...CommandeFlashDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailCommandeFlashForm,
    element: <Vente.DetailCommandeFlashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH,
        CODE_SERVICE.VENTE_ORGANISATION.LECTURE_COMMANDE_FLASH_BY_ORGANISATION,
        CODE_SERVICE.VENTE_ORGANISATION.LECTURE_COMMANDE_FLASH_BY_UID,
        CODE_SERVICE.VENTE_ORGANISATION.INITIER_COMMANDE_FLASH_MODE_RECOUVREMENT
    ]
};

/**
 * @desc Commande flash
 */

export const CommandeFlashSenderViewLayoutPath = {
    ...CommandeFlashSenderViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CommandeFlashSenderViewLayout,
    element: <Vente.CommandeFlashSenderViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_FLASH_BY_SENDER,
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_FLASH_SENDER
    ]
};

export const CommandeFlashSenderDetailPath = {
    ...CommandeFlashSenderDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailCommandeFlashSenderForm,
    element: <Vente.DetailCommandeFlashSenderForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_FLASH_BY_SENDER,
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_FLASH_SENDER
    ]
};

/**
 * @desc Commande Emoney
 */

export const CommandeEmoneySenderViewLayoutPath = {
    ...CommandeEmoneySenderViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CommandeEmoneySenderViewLayout,
    element: <Vente.CommandeEmoneySenderViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_EMONEY_BY_SENDER,
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_EMONEY_SENDER
    ]
};

export const CommandeEmoneySenderDetailPath = {
    ...CommandeEmoneySenderDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailCommandeEmoneySenderForm,
    element: <Vente.DetailCommandeEmoneySenderForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_EMONEY_BY_SENDER,
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_EMONEY_SENDER
    ]
};

/**
 * @desc Commande Emoney Retrait
 */

export const CommandeRetraitEmoneySenderViewLayoutPath = {
    ...CommandeRetraitEmoneySenderViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CommandeEmoneyAutorisationRetrait,
    element: <Vente.CommandeEmoneyAutorisationRetrait />,
    code: [
        { code: 'test' },
        CODE_SERVICE.CODE_SERVICE_SENDER.LECTURE_COMMANDE_EMONEY_BY_SENDER,
        CODE_SERVICE.CODE_SERVICE_SENDER.MISE_A_JOUR_COMMANDE_EMONEY_SENDER
    ]
};

/**
 * @desc Retrait cash
 */

export const RetraitCashViewLayoutPath = {
    ...RetraitCashViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.RetraitCashViewLayout,
    element: <Vente.RetraitCashViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RETRAIT_CASH_EMONEY_BY_OWNER,
        CODE_SERVICE.SERVICE_EMONEY.VALIDER_RETRAIT_CASH_EMONEY
    ]
};

export const RetraitCashDetailPath = {
    ...RetraitCashDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailRetraitCashForm,
    element: <Vente.DetailRetraitCashForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.LECTURE_RETRAIT_CASH_EMONEY_BY_OWNER,
        CODE_SERVICE.SERVICE_EMONEY.VALIDER_RETRAIT_CASH_EMONEY
    ]
};

/**
 * @desc Depot Emoney
 */

export const DepotEmoneyCreatePath = {
    ...DepotEmoneyCreateRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.CreationDepotEmoneyForm,
    element: <Vente.CreationDepotEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

export const DepotEmoneyViewLayoutPath = {
    ...DepotEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DepotEmoneyViewLayout,
    element: <Vente.DepotEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

export const DepotEmoneyDetailPath = {
    ...DepotEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailDepotEmoneyForm,
    element: <Vente.DetailDepotEmoneyForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY,
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

/**
 * @desc Recouvrement Emoney
 */

export const RecouvrementEmoneyViewLayoutPath = {
    ...RecouvrementEmoneyViewLayoutRelPath,
    isVisible: true,
    dependencies: ['PrincipalMenu'],
    Component: Vente.RecouvrementEmoneyViewLayout,
    element: <Vente.RecouvrementEmoneyViewLayout />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};

export const RecouvrementEmoneyDetailPath = {
    ...RecouvrementEmoneyDetailRelPath,
    isVisible: false,
    dependencies: ['PrincipalMenu'],
    Component: Vente.DetailRecouvrementForm,
    element: <Vente.DetailRecouvrementForm />,
    code: [
        { code: 'test' },
        CODE_SERVICE.SERVICE_EMONEY.INITIER_DEPOT_EMONEY_MODE_RECOUVREMENT
    ]
};
