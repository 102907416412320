import { useCallback } from 'react';
import {
    useFormGlobalReducer,
    fetchElement,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    FORM_CONST,
    TODAY,
    checkPlageDate,
    formInitialState
} from '@napp-inc/jnapp-util';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../releve/generic';
import { URL_CONST } from '../../../util';
import { MarchandSelector } from '../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    numeroClient: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    selectedMarchand: {}
};

export function RapportDepotEmoneyAProblemeSearchForm() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser
    }));
    const { idToken } = useGetUserDetail({ firebaseUser });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;
    const fetchReport = () => {
        const { dateFin, dateDebut, selectedMarchand } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        if (!selectedMarchand.code) {
            formDispatcher({
                type: FORM_CONST.SET_FORM_ERROR,
                payload: {
                    message: 'Veuillez selectionner un marchand',
                    status: FORM_CONST.CHARGEMENT_ECHEC
                }
            });
            return;
        }
        const payload = {
            dateDebut,
            dateFin,
            codeMarchand: selectedMarchand.code
        };
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_DEPOT_A_PROBLEME,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };

    const getDesignationMarchand = useCallback(() => {
        if (!fields.selectedMarchand.code) return null;
        return `Marchand ${fields.selectedMarchand.designation}`;
    }, [fields.selectedMarchand.code, fields.selectedMarchand.designation])();

    return (
        <>
            <MainReportComponentLarge
                listTitle={getDesignationMarchand}
                bottomPagination
                isDataFetched={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_SUCCESS
                }
                isDetaille={false}
                formState={formState}
                formDispatcher={formDispatcher}
                fetchReport={fetchReport}
                mapper={[
                    {
                        field: 'numero',
                        displayName: 'Numéro'
                    },
                    {
                        field: 'montantDebiter',
                        displayName: 'Montant Débité'
                    },
                    {
                        field: 'montantFrais',
                        displayName: 'Frais'
                    },
                    {
                        field: 'devise',
                        displayName: 'Dévise'
                    },
                    {
                        field: 'numeroReference',
                        displayName: 'Référence'
                    },
                    {
                        field: 'shop',
                        displayName: 'Shop'
                    },
                    {
                        field: 'valeurTypeOrganisation.numeroTel',
                        displayName: 'Numéro à Contacter'
                    },
                    {
                        field: 'agentRecuteur',
                        displayName: 'Agent Recruteur'
                    },
                    {
                        field: 'organisationRecuteur',
                        displayName: 'Organisation Recruteur'
                    },
                    {
                        field: 'dateOperation',
                        displayName: 'Date Opération',
                        isDate: true
                    },
                    {
                        field: 'datePartenaire',
                        displayName: 'Date Partenaire',
                        isDate: true
                    },
                    {
                        field: 'dateDerniereModification',
                        displayName: 'Dernière modification'
                    }
                ]}
                dataList={(elements.mouvements?.value || [])?.map((item) => ({
                    ...item,
                    agentRecuteur: `${item?.recrutement?.agent?.prenom || ''} ${
                        item?.recrutement?.agent?.nom || ''
                    }`,
                    organisationRecuteur: `${
                        item?.recrutement?.organisation?.designation || ''
                    }`,
                    shop: `${item?.valeurTypeOrganisation?.designation || ''}`
                }))}
                isFetching={
                    elements?.mouvements?.status ===
                    FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                }
            >
                <MarchandSelector
                    formState={formState}
                    formDispatcher={formDispatcher}
                    estNonAirtime
                    idProperty="code"
                />
            </MainReportComponentLarge>
            {/* <StateShowerComponent state={elements} /> */}
        </>
    );
}
