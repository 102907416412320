/**
 * formatPhoneNumber()
 * Cette fonction formatte le numéro de téléphone et renvoie le resultat dans un format sans indicatif international
 * @param {String} param0.numero : Numéro de téléphone
 * @returns {String}
 */

export function formatPhoneNumber({ numero }) {
    // Liste des préfixes internationaux (indicatifs de pays, pour l'instant on ne mettra que la RDC)
    const countryPrefixes = ['243'];

    // Nettoyer le numéro de téléphone (enlever tout caractère non numérique)
    let cleanedPhone = numero.replace(/\D/g, '');

    // Supprimer le préfixe "+" s'il existe
    if (cleanedPhone.startsWith('+')) {
        cleanedPhone = cleanedPhone.slice(1);
    }

    // Supprimer le préfixe "00" s'il existe (utilisé pour les numéros internationaux)
    if (cleanedPhone.startsWith('00')) {
        cleanedPhone = cleanedPhone.slice(2);
    }

    // Vérifier dans le tableau countryPrexises[] s'il existe au moins un element qui satisfait la condition
    countryPrefixes.some((prefix) => {
        if (cleanedPhone.startsWith(prefix)) {
            // Supprimer l'indicatif du pays
            cleanedPhone = cleanedPhone.slice(prefix.length);
            return true;
        }
        return false;
    });

    // Si le numéro commence par un zéro
    if (cleanedPhone.startsWith('0')) {
        cleanedPhone = cleanedPhone.slice(1);
    }

    return cleanedPhone;
}
