import { useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { downloadFileByUrlAction, fetchElement } from '@napp-inc/jnapp-hook';
import { FORM_CONST } from '@napp-inc/jnapp-util';
import {
    CustomButtonLoader,
    DetailWrapper,
    SimpleTable
} from '../../../components';
import { REDUX_NODE_NAME } from '../../../redux';
import { extractTXTFileInfo, URL_CONST, useGetDetailForm } from '../../../util';
import { schema } from './detail-fields';
import { releveEmoney } from '../../../firebase';

/**
 * @desc defaultFiles
 */
const defaultFiles = ['instruction'];

export function DetailUploadFichierReleveEmoneyForm() {
    const { id, reduxDispatcher, entity, idToken, formState, formDispatcher } =
        useGetDetailForm({
            nodeName: 'relevesEmoney',
            defaultElements: ['retrait'],
            defaultFiles
        });
    const { elements, files } = formState;

    const nameOfFile = entity?.fileName ? entity?.fileName : entity?.fileName;

    const onFetchDetailRetraitById = () => {
        if (!id) return;
        const { status: etat } = elements.retrait;
        formDispatcher(
            fetchElement({
                idToken,
                functionName: URL_CONST.GET_LIST_RETRAIT_BY_RELEVE,
                elementPropertyName: 'retrait',
                etat,
                payload: {
                    idTraitementReleveEmoney: id
                },
                reduxDispatcher
            })
        );
    };
    const downloadFunction = () => {
        formDispatcher(
            downloadFileByUrlAction({
                getDownloadURL,
                fileRef: ref(releveEmoney, nameOfFile),
                propertyName: 'instruction',
                isBlob: true
            })
        );
    };
    useEffect(() => {
        if (
            files.instruction?.status === FORM_CONST.FILE_DOWNLOADED &&
            !!entity?.fileName
        ) {
            extractTXTFileInfo({
                props: files.instruction,
                fileName: nameOfFile
            });

            const link = document.createElement('a');
            link.href = files.instruction?.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        return () => {};
    }, [
        nameOfFile,
        files.instruction,
        files.instruction?.status,
        entity?.fileName
    ]);
    const renderContent = () => (
        <DetailWrapper
            functionName={URL_CONST.GET_TRAITEMENT_RELEVE_EMONEY_BY_ID}
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_EMONEY}
            formDispatcher={formDispatcher}
            formState={formState}
            reduxDispatcher={reduxDispatcher}
            subtitle="Releve Emoney"
            entity={entity}
            id={id}
            schema={schema}
            idToken={idToken}
            downloadFileFunction={downloadFunction}
            isDownloadFile={!!entity?.fileName || !!entity?.fileName}
        >
            <div>
                <CustomButtonLoader
                    type="button"
                    className="btn-success mt-2"
                    onClick={onFetchDetailRetraitById}
                    text="Voir Détails"
                    disabled={
                        elements?.retrait?.status ===
                        FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
                    }
                />
                <SimpleTable
                    filter
                    tableClassName=""
                    isShowDropButton={false}
                    data={
                        (elements?.retrait?.value || [])?.map((item) => ({
                            ...item,
                            clientDesignation: `${
                                item?.client?.designation || ''
                            } ${item?.client?.numero || ''}`
                        })) || []
                    }
                    identifier="id"
                    mapper={[
                        {
                            displayName: 'Client',
                            field: 'clientDesignation'
                        },
                        {
                            field: 'montant',
                            displayName: 'montant'
                        },
                        {
                            field: 'devise',
                            displayName: 'Devise'
                        },
                        {
                            field: 'etat',
                            displayName: 'Etat'
                        },
                        {
                            field: 'numeroReference',
                            displayName: 'Référence'
                        },
                        {
                            field: 'dateDerniereModification',
                            displayName: 'Date Modification'
                        }
                    ]}
                    isLinkToDetail={false}
                />
            </div>
        </DetailWrapper>
    );
    return renderContent();
}
