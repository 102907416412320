import {
    fetchElement,
    useFormGlobalReducer,
    useGetUserDetail
} from '@napp-inc/jnapp-hook';
import {
    checkPlageDate,
    FORM_CONST,
    formInitialState,
    TODAY
} from '@napp-inc/jnapp-util';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainReportComponentLarge } from '../../../report/releve/generic';
import { formatPhoneNumber, URL_CONST } from '../../../../util';
import { SeviceClientClientRetraitSelector } from '../../../../components';

const defaultFields = {
    debut: '',
    fin: '',
    dateDebut: TODAY.at000000(),
    dateFin: TODAY.at235959(),
    selectedDateRangeReport: {
        code: 'TODAY',
        designation: "Aujourd'hui"
    },
    isFetching: false,
    numeroReference: '',
    numero: '',
    tillNumber: '',
    selectedTypeOperation: {},
    selectedMarchand: {},
    selectedDevise: {},
    montant: 0
};

export function ClientCommandeEmoney() {
    const reduxDispatcher = useDispatch();
    const { firebaseUser, nappUser } = useSelector((reduxState) => ({
        firebaseUser: reduxState.firebaseUser,
        nappUser: reduxState.nappUser
    }));
    const { idToken } = useGetUserDetail({
        firebaseUser,
        nappUser
    });
    const initialState = formInitialState({
        fields: defaultFields,
        elements: ['mouvements']
    });
    const { state: formState, dispatch: formDispatcher } = useFormGlobalReducer(
        {
            initialState
        }
    );
    const { fields, elements } = formState;

    const getMapperByTypeRetrait = useCallback(() => {
        if (fields.selectedTypeOperation.code === 'NON_ORG') {
            return [
                {
                    displayName: 'Marchand',
                    field: 'marchand.designation'
                },
                {
                    field: 'client.numero',
                    displayName: 'Numéro'
                },
                {
                    field: 'montant',
                    displayName: 'Montant'
                },
                {
                    displayName: 'Devise',
                    field: 'devise'
                },
                {
                    field: 'etat',
                    displayName: 'Etat'
                },
                {
                    field: 'numeroReference',
                    displayName: 'Référence'
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création'
                },
                {
                    field: 'dateDerniereModification',
                    displayName: 'Dernière modification'
                }
            ];
        }
        return [
            {
                displayName: 'Marchand',
                field: 'marchand.designation'
            },
            {
                field: 'designationClient',
                displayName: 'Client'
            },
            {
                field: 'client.numero',
                displayName: 'Numéro'
            },
            {
                field: 'montant',
                displayName: 'Montant'
            },
            {
                displayName: 'Devise',
                field: 'devise'
            },
            {
                field: 'etat',
                displayName: 'Etat'
            },
            {
                field: 'numeroReference',
                displayName: 'Référence'
            },
            {
                field: 'dateCreation',
                displayName: 'Création'
            },
            {
                field: 'dateDerniereModification',
                displayName: 'Dernière modification'
            }
        ];
    }, [fields.selectedTypeOperation.code])();
    // const { mapper } = setDefaultProperties({ type: typeEntite });
    const fetchReport = () => {
        const { dateFin, dateDebut } = fields;
        const { isValid } = checkPlageDate(dateDebut, dateFin);
        if (!isValid) {
            return;
        }
        const payload = {
            estOrganisation: fields.selectedTypeOperation.code === 'ORG',
            numeroClient: formatPhoneNumber({ numero: fields.numero })
        };
        if (fields.selectedTypeOperation.code === 'NON_ORG') {
            payload.numeroReference = fields.numeroReference;
        }
        if (fields.selectedTypeOperation.code === 'ORG') {
            payload.tillNumber = fields.tillNumber;
            payload.devise = fields.selectedDevise.code;
            payload.montant = fields.montant;
            if (dateDebut !== TODAY.at000000()) {
                payload.dateDebut = dateDebut;
                payload.dateFin = dateFin;
            }
        }
        if (fields?.selectedMarchand?.code) {
            payload.codeMarchand = fields?.selectedMarchand?.code;
        }
        formDispatcher(
            fetchElement({
                idToken,
                elementPropertyName: 'mouvements',
                payload,
                functionName: URL_CONST.GET_LIST_RETRAIT_CLIENT,
                etat: elements.mouvements.status,
                formDispatcher,
                reduxDispatcher
            })
        );
    };
    return (
        <MainReportComponentLarge
            isDataFetched={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_SUCCESS
            }
            isDetaille={false}
            formState={formState}
            formDispatcher={formDispatcher}
            fetchReport={fetchReport}
            mapper={getMapperByTypeRetrait}
            dataList={
                (elements.mouvements?.value || []).map((item) => ({
                    ...item,
                    designationClient: `${item?.client?.prenom || ''} ${
                        item?.client?.nom || ''
                    }`
                })) || []
            }
            isFetching={
                elements?.mouvements?.status ===
                FORM_CONST.FETCH_ELEMENT_IN_PROGRESS
            }
        >
            <SeviceClientClientRetraitSelector
                formDispatcher={formDispatcher}
                formState={formState}
            />
        </MainReportComponentLarge>
    );
}
