import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST, useGetReportDetail } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';

export function ListHistoriqueUploadFichierReleveEmoneyForm() {
    const { formState, formDispatcher } = useGetReportDetail({});
    return (
        <ReduxReportTemplate
            listTitle=""
            isLinkToDetail
            dynamicNodeName={`relevesEmoney-${TIME.utc000000(
                TODAY.at000000()
            )}-${TIME.utc235959(TODAY.at235959())}`}
            functionName={URL_CONST.GET_LIST_TRAITEMENT_RELEVE_EMONEY}
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_EMONEY}
            formState={formState}
            formDispatcher={formDispatcher}
            mapper={[
                {
                    field: 'superDealer.designation',
                    displayName: 'Super Dealer',
                    position: 1
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand',
                    position: 2
                },
                {
                    field: 'fileName',
                    displayName: 'Fichier',
                    position: 3
                },
                {
                    field: 'nombre',
                    displayName: 'Opérations',
                    position: 4
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 5
                }
            ]}
            listFormater={(data) => {
                if (data?.length && data?.[0]?.length) return data?.[0];
                if (data?.length && !data?.[0]?.length) return [];
                return data;
            }}
            payloadMapper={[
                {
                    name: 'dateDebut',
                    property: 'dateDebut',
                    isRequired: true,
                    displayName: 'Date début'
                },
                {
                    name: 'dateFin',
                    property: 'dateFin',
                    isRequired: true,
                    displayName: 'Date fin'
                }
            ]}
        />
    );
}
