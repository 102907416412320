export const schema = [
    {
        title: 'Classe',
        description: 'classe',
        className: 'border-bottom'
    },
    {
        title: 'Periode Debut',
        description: 'periode.dateDebut',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Periode Fin',
        description: 'periode.dateFin',
        className: 'border-bottom',
        isDate: true
    },
    {
        title: 'Date',
        description: 'dateCreation',
        className: 'border-bottom'
    },
    {
        title: 'Etat',
        description: 'etat'
    }
];
