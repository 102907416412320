import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import {
    CODE_SERVICE,
    DB_NODE,
    FETCH_LISTENING,
    FILTER_OPERATOR,
    FILTER_TYPE,
    TIME,
    TODAY,
    TYPE_ORGANISATION
} from '@napp-inc/jnapp-util';
import { URL_CONST, useGetReportDetail, useHasService } from '../../../util';
import { ReduxReportTemplate } from '../../report/template';
import { REDUX_NODE_NAME } from '../../../redux';
import {
    ConditionalRenderingWrapper,
    CustomRow,
    CustomSwitchButton,
    ListGenericForm
} from '../../../components';

const formater = (data = []) => {
    if (typeof data === 'object' && !!data?.[0]) {
        const x = data[0];
        return x;
    }
    if (typeof data === 'object' && data?.length) {
        return data;
    }
    return [];
};

const setDefaultProperties = ({ type, service, uid, oid }) => {
    // Default Mapper
    const defaultMapper = [
        {
            field: 'numeroReference',
            displayName: 'Reference'
        },
        {
            field: 'imputation.designation',
            displayName: 'Imputation'
        },
        {
            field: 'typeOperation',
            displayName: 'Operation'
        },
        {
            field: 'caisse.designation',
            displayName: 'Caisse'
        },
        {
            field: 'montant',
            displayName: 'Montant'
        },
        {
            field: 'devise',
            displayName: 'Devise'
        },
        {
            field: 'etat',
            displayName: 'Etat'
        },
        {
            displayName: 'Création',
            field: 'dateCreation'
        }
    ];

    // DefaultListerners
    let filters = [];
    if (
        (service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .LECTURE_REGULARISATION_CAISSE?.code
        ] ||
            service[
                CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                    .INITIER_CREATION_REGULARISATION_CAISSE?.code
            ]) &&
        !service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .VALIDER_REGULARISATION_CAISSE?.code
        ]
    ) {
        filters = [
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'typeEntite',
                value: type,
                isRequired: true
            },
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'valeurTypeEntite.reference',
                value: oid,
                isRequired: true
            }
        ];
    }
    if (
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .VALIDER_REGULARISATION_CAISSE?.code
        ]
    ) {
        filters = [
            {
                type: FILTER_TYPE.WHERE,
                operator: FILTER_OPERATOR.EQUAL,
                property: 'creator',
                value: uid,
                isRequired: true
            }
        ];
    }
    if (
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .LECTURE_REGULARISATION_CAISSE?.code
        ] ||
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_CREATION_REGULARISATION_CAISSE?.code
        ] ||
        service[
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .VALIDER_REGULARISATION_CAISSE?.code
        ]
    ) {
        filters = filters.concat([
            {
                property: 'dateDerniereModification',
                value: 'asc',
                type: FILTER_TYPE.ORDER_BY
            },
            {
                type: FILTER_TYPE.START_AT,
                value: TODAY.nowUtc(),
                isTimestampNowValue: true
            }
        ]);
    }
    if (type === TYPE_ORGANISATION.NAPP)
        return {
            mapper: [
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                {
                    field: 'estNumeroReferenceFictif',
                    displayName: 'Numéro Fictif',
                    isBoolean: true
                },
                ...defaultMapper
            ],
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.DISTRIBUTEUR)
        return {
            mapper: [
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                ...defaultMapper
            ],
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.REVENDEUR)
        return {
            mapper: [
                {
                    field: 'valeurTypeEntite.designation',
                    displayName: 'Organisation'
                },
                ...defaultMapper
            ],
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.SUPER_DEALER)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    if (type === TYPE_ORGANISATION.CASH_COLLECTEUR)
        return {
            mapper: defaultMapper,
            listerners: filters
        };
    return { mapper: [], listerners: [] };
};

export function ListRegularisationCaisseEmoneyForm() {
    /**
     * Get data on redux store
     */
    const { nappUser, firebaseUser, organisation } = useSelector(
        (reduxState) => ({
            nappUser: reduxState.nappUser,
            firebaseUser: reduxState.firebaseUser,
            organisation: reduxState.organisation
        })
    );
    const { hasService } = useHasService({
        services: [
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .INITIER_CREATION_REGULARISATION_CAISSE,
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .VALIDER_REGULARISATION_CAISSE,
            CODE_SERVICE.CAISSE.REGULARISATION_CAISSE
                .LECTURE_REGULARISATION_CAISSE
        ]
    });
    /**
     * Common form processes
     */
    const { oid, typeEntite } = useGetUserDetail({
        nappUser,
        firebaseUser,
        organisation
    });

    const { mapper, listerners } = setDefaultProperties({
        type: typeEntite,
        oid,
        reference: nappUser?.valeurTypeEntite?.reference,
        service: hasService
    });
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: { isDate: false }
    });
    const { fields } = formState;

    const renderContent = () => (
        <>
            <CustomRow>
                <CustomSwitchButton
                    formDispatcher={formDispatcher}
                    formState={formState}
                    id="estDateRegularisationCaisseId"
                    text="Vue datée?"
                    value={fields.isDate}
                    name="isDate"
                    divClassName="ms-2 col-md-8"
                />
            </CustomRow>
            <ConditionalRenderingWrapper isShouldBeRendered={!fields.isDate}>
                <ListGenericForm
                    fetchType={FETCH_LISTENING}
                    path={DB_NODE.REGULARISATION_CAISSE}
                    reduxPropertyName="regularisationsCaisse"
                    functionName={
                        URL_CONST.GET_LIST_REGULARISATION_CAISSE_INITIALS
                    }
                    nodeName={DB_NODE.REGULARISATION_CAISSE}
                    mapper={mapper}
                    listenerFilters={listerners}
                />
            </ConditionalRenderingWrapper>
            <ConditionalRenderingWrapper isShouldBeRendered={fields.isDate}>
                <ReduxReportTemplate
                    dynamicNodeName={`regularisationsCaisse-${TIME.utc000000(
                        TODAY.at000000()
                    )}-${TIME.utc235959(TODAY.at235959())}`}
                    nodeName={REDUX_NODE_NAME.REGULARISATION_CAISSE_EMONEY}
                    functionName={
                        URL_CONST.GET_LIST_REGULARISATION_CAISSE_INITIALS
                    }
                    formState={formState}
                    formDispatcher={formDispatcher}
                    listFormater={formater}
                    payload={{
                        dateDebut: TIME.utc000000(TODAY.at000000()),
                        dateFin: TIME.utc235959(TODAY.at235959())
                    }}
                    payloadMapper={[
                        {
                            name: 'dateDebut',
                            property: 'dateDebut',
                            isRequired: true,
                            displayName: 'Date début'
                        },
                        {
                            name: 'dateFin',
                            property: 'dateFin',
                            isRequired: true,
                            displayName: 'Date fin'
                        }
                    ]}
                    mapper={mapper}
                />
            </ConditionalRenderingWrapper>
        </>
    );
    return renderContent();
}
