import { TIME, TODAY } from '@napp-inc/jnapp-util';
import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { ListGenericForm } from '../../../components';

export function ListUploadFichierReleveEmoneyForm() {
    return (
        <ListGenericForm
            reduxPropertyName="relevesEmoney"
            functionName={URL_CONST.GET_LIST_TRAITEMENT_RELEVE_EMONEY}
            nodeName={REDUX_NODE_NAME.UPLOAD_RELEVE_EMONEY}
            payload={{
                dateDebut: TIME.utc000000(TODAY.at000000()),
                dateFin: TIME.utc235959(TODAY.at235959())
            }}
            mapper={[
                {
                    field: 'superDealer.designation',
                    displayName: 'Super Dealer',
                    position: 1
                },
                {
                    field: 'marchand.designation',
                    displayName: 'Marchand',
                    position: 2
                },
                {
                    field: 'fileName',
                    displayName: 'Fichier',
                    position: 3
                },
                {
                    field: 'nombre',
                    displayName: 'Opérations',
                    position: 4
                },
                {
                    field: 'dateCreation',
                    displayName: 'Création',
                    position: 5
                }
            ]}
        />
    );
}
