import { REDUX_NODE_NAME } from '../../../redux';
import { URL_CONST } from '../../../util';
import { ListGenericForm } from '../../../components';

export function ListTachesPerformanceAgentForm() {
    const getOrder = (value) => {
        if (value === 'asc') return 'Ascendant';
        if (value === 'desc') return 'Descendant';
        return '';
    };
    return (
        <ListGenericForm
            reduxPropertyName="tachesPerformanceAgent"
            functionName={URL_CONST.GET_LIST_TACHE_PERFORMANCE_AGENT}
            nodeName={REDUX_NODE_NAME.TACHES_PERFORMANCE_AGENT}
            formater={(items) =>
                items.map((item) => ({ ...item, id: item.code }))
            }
            mapper={[
                {
                    field: 'designation',
                    displayName: 'Tâche'
                },
                {
                    field: 'typeTache',
                    displayName: 'Type'
                },
                {
                    field: 'typeDelai',
                    displayName: 'Delais'
                },
                {
                    field: 'indice',
                    displayName: 'Indice'
                },
                {
                    field: 'quota',
                    displayName: 'Quota'
                },
                {
                    field: 'typeIndicePerformance',
                    displayName: 'Indice de Perfomance'
                },
                {
                    field: 'ordreCorrespondance',
                    displayName: 'Ordre',
                    tdExtractor: (item) => getOrder(item.ordreCorrespondance)
                }
            ]}
        />
    );
}
