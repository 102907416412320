import { useCallback, useState } from 'react';
import { TYPE_ORGANISATION } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { useGetUserDetail } from '@napp-inc/jnapp-hook';
import { RapportCommandeEmoneyForm } from './rapport-commande-emoney';
import { RapportDepotEmoneySearchForm } from './depot-emoney-search';
import { RapportDepotEmoneyAProblemeSearchForm } from './depot-a-probleme';
import { TabsRendering } from '../../../components';

export function RapportCommandeEmoneyViewLayout() {
    /**
     * Get data on redux store
     */
    const { nappUser } = useSelector((reduxState) => ({
        nappUser: reduxState.nappUser
    }));
    /**
     * Common form processes
     */
    const { typeEntite } = useGetUserDetail({
        nappUser
    });
    const [key, setKey] = useState('report');

    const getTabData = useCallback(
        () => [
            {
                eventKey: 'report',
                title: 'Rapport emoney',
                content: <RapportCommandeEmoneyForm />,
                isCondition: true
            },
            {
                eventKey: 'depot',
                title: 'Rechercher Dépôt',
                content: <RapportDepotEmoneySearchForm />,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            },
            {
                eventKey: 'depotAProbleme',
                title: 'Dépôt à problème',
                content: <RapportDepotEmoneyAProblemeSearchForm />,
                isCondition: typeEntite === TYPE_ORGANISATION.NAPP
            }
        ],
        [typeEntite]
    );

    const tabData = getTabData();

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Rapport Emoney</h1>
            </div>
            <TabsRendering data={tabData} onSelect={setKey} selectedKey={key} />
        </div>
    );
}
