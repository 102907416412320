const getOrder = (value) => {
    if (value === 'asc') return 'Ascendant';
    if (value === 'desc') return 'Descendant';
    return '';
};

export const schema = [
    // {
    //     title: 'Id',
    //     description: 'id',
    //     className: 'border-bottom'
    // },
    {
        description: 'designation',
        title: 'Tâche',
        className: 'border-bottom'
    },
    {
        description: 'typeTache',
        title: 'Type',
        className: 'border-bottom'
    },
    {
        description: 'typeDelai',
        title: 'Delais',
        className: 'border-bottom'
    },
    {
        description: 'indice',
        title: 'Indice',
        className: 'border-bottom'
    },
    {
        description: 'quota',
        title: 'Quota',
        className: 'border-bottom'
    },
    {
        description: 'typeIndicePerformance',
        title: 'Indice de Perfomance',
        className: 'border-bottom'
    },
    {
        description: 'ordreCorrespondance',
        title: 'Ordre',
        tdExtractor: (item) => getOrder(item.ordreCorrespondance)
    }
];
